
  
  .contentnotfound {
    text-align: center;
    padding-top: 118px;
    color: #CDD4DE;
    width: 100%;
    height: auto;
    float: left;
    position: relative;
    overflow: hidden;
  }
  
  .contentnotfound h1 {
    font-weight: 900;
    font-size: 165px;
    line-height: 1;
    margin-bottom: -10px;
    opacity: 0.6;
  }
  
  .contentnotfound h2 {
    font-weight: 700;
    font-size: 34px;
    margin-bottom: 6px;
    opacity: 0.9;
  }
  
  .contentnotfound p {
    font-weight: 300;
    font-size: 14px;
    opacity: 0.7;
    margin-bottom: 140px;
  }
  
  .contentnotfound a {
    display: inline-block;
    font-weight: 300;
    font-size: 12px;
    text-transform: uppercase;
    border: 1px solid #CDD4DE;
    padding: 8px 14px;
    border-radius: 4px;
    opacity: 0.4;
    cursor: pointer;
  }
  
  .contentnotfound img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1;
    transform: scale(1.1);
  }
  