@font-face {
  font-family: "poppinslight";
  src: url("./assets/fonts/poppins-light-webfont.woff2") format("woff2"),
    url("./assets/fonts/poppins-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "poppinsmedium";
  src: url("./assets/fonts/poppins-medium-webfont.woff2") format("woff2"),
    url("./assets/fonts/poppins-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "poppinsregular";
  src: url("./assets/fonts/poppins-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/poppins-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "poppinsbold";
  src: url("./assets/fonts/poppins-bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/poppins-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,900&display=swap");

body {
  font-family: "poppinsregular"; 
  font-weight: 400;
/* background-color: #f3f3f3; */
}

a {
  cursor: pointer;  
}
head {
  background-color: #fff;
}
.topMainHead {
  width: 100%;
  height: auto;
  float: left;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  padding-bottom: 10px;
  background-color: #fff;
  /*  box-shadow: 0px 0px 20px #e1e1e1;
  box-shadow: 0px 0px 20px #e1e1e1; */
}
.topMainHead .logoTopSection {
  width: 100%;
  height: auto;
  float: left;
}
.topMainHead .logoTopSection img {
  max-width: 200px;
}
.topMainHead .navbar-expand-lg .navbar-nav li {
  display: flex;
  align-items: center;
}
.topMainHead .navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 15px !important;
  padding-left: 15px !important;
  color: #242424;
  background: none;
  border: none;
}
.topMainHead .navbar-expand-lg .navbar-nav .nav-link.manageBookingBtn {
  padding: 10px 20px !important;
  background: #fff;
  border: 1px solid #d2f4ff;
  box-shadow: 0px 7px 14px rgba(136, 136, 136, 0.13);
  border-radius: 40px;
  margin-left: 10px;
}
.topMainHead .navbar-expand-lg .navbar-nav .nav-link.accountLoginBoxTop {
  padding: 10px 20px !important;
  background: #fff;
  border: 1px solid #d2f4ff;
  box-shadow: 0px 7px 14px rgba(136, 136, 136, 0.13);
  border-radius: 40px;
  margin-left: 10px;
  padding: 15px;
  width: 87%;
  color: #000;
}
.topMainHead .navbar-expand-lg .navbar-nav .nav-link.accountLoginBoxTop:hover {
  background-color: #d1af77 !important;
  color: #242424;
}
.topMainHead .topLoginAccLnk {
  text-align: center !important;
}
.topMainHead .topLoginAccLnk p {
  font-size: 15px !important;
  margin: 10px 0 !important;
}
.topMainHead .topLoginAccLnk .topSigninBtn {
  width: 100%;
  height: 40px;
  background-color: #d1af77;
  text-align: center;
  color: #fff !important;
  min-width: 100px;
  box-shadow: 0px 4px 14px rgb(245 217 170);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.2s ease;
  margin: 10px 0;
  text-decoration: none;
}
.topMainHead .topLoginAccLnk .topSigninBtn:hover {
  background-color: #002d3a;
}
.topMainHead .topLoginAccLnk a {
  width: 100%;
  display: inline-block;
  text-decoration: none;
  text-align: center !important;
  color: #242424;
}
.topMainHead .topLoginAccLnk .dropdown-menu {
  right: 0;
  left: inherit;
}
.topMainHead .navbar-expand-lg .navbar-nav .dropdown-menu {
  border: 0;
  min-width: 280px;
  padding: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1490196078);
  top: 60px;
}
.topMainHead .navbar-expand-lg .navbar-nav .dropdown-menu a {
  color: #000;
  position: relative;
  margin: 5px 0;
  padding: 5px;
}
.topMainHead .navbar-expand-lg .navbar-nav .dropdown-menu p {
  width: 100%;
  font-size: 12px;
  margin-bottom: 0;
}
.topMainHead .navbar-expand-lg .navbar-nav .dropdown-menu span {
  position: absolute;
  right: 10px;
  top: 10px;
}

.fixed-header {
  background-color: #fff !important;
  padding: 10px 0 !important;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1490196078) !important;
}

/********Head style end********/
.topMainBanner {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
  overflow: hidden;
}
.topMainBanner img {
  width: 100%;
}
.topMainBanner .banner_top_cnt_bx {
  width: 100%;
  height: 80px;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 35%;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  flex-direction: column;
}
.topMainBanner .banner_top_cnt_bx h1 {
  width: 100%;
  text-align: center;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 57px;
  font-family: "poppinsbold";
}
.h1 {
  color: white;
}

.topMainBanner .banner_top_cnt_bx h1 span {
  font-size: 44px;
  width: 100%;
  display: inline-block;
  font-family: "poppinsbold";
}
.topMainBanner .banner_top_cnt_bx p {
  width: 100%;
  font-style: normal;
  font-size: 21px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #000;
}

.bookingFomrBox {
  width: 100% ;
  height: auto;
  float: left;
  padding: 15px;
  background: #ffffffe8;
  box-shadow: 8px 9px 44px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  z-index: 99;
  position: relative;
  margin-top: -20%;
}
.bookingFomrBox .bookingFomrBoxHeadSc {
  width: auto ;
  height: auto ;
  float: left ;
  align-items: center ;
  display: flex ;
  justify-content: center ;
  position: relative ;
  margin-top: 10px ;
}
.bookingFomrBox .bookingFomrBoxHeadSc .bookingFomrBoxHeadtxt {
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  min-width: 300px;
  color: #000;
  position: absolute;
  left: 0;
  font-weight: bold;
}
.bookingFomrBoxHeabtns {
  display: flex;
  gap: 20px;
  border: none;
  justify-content: start;
  margin-top: -57px;
  margin-left: -15px;
}
.bookingFomrBoxHeabtns button {
  color: #ccc;
  text-decoration: none;
  border: 0 !important;
}
.bookingFomrBoxHeabtns button:hover {
  color: #fff;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border: 0 !important;
}
.bookingFomrBoxHeabtns button.active {
  color: #d1af77 !important;
  border-bottom: 2px solid #d1af77 !important;
  border-left: none;
  border-right: none;
  border-top: none;
}
.bookingFomrBox .searchBookBtmRow {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  /* margin-top: 7px; */
  float: left;
}
.bookingFomrBox .searchBookBtmRow .searchBookBtmLocation {
  width: 45%;
  height: auto;
  float: left;
  background: #ffffff;
  border: 0.5px solid #cccccc;
  border-radius: 10px;
  display: flex;
  /* overflow: hidden; */
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding-top: 5px;
  height: 74px;
}
.bookingFomrBox .searchBookBtmRow .searchBookBtmLocation .searchBookBtmBox {
  width: 100%;
  height: auto;
  float: left;
  max-width: 255px;
}
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  .searchBookBtmName {
  width: 100%;
  height: auto;
  float: left;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #000;
  padding-left: 10px;
}
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  .searchBookBtmInput {
  width: 100%;
  height: 67px;
  margin-top: -4px;
  margin-bottom: 3px;
  padding-left: 10px;
  border: 0;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
}
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  input:valid
  ~ label,
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  textarea:valid
  ~ label,
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  input:focus
  ~ label,
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  textarea:focus
  ~ label,
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  input:valid
  ~ label,
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  textarea:valid
  ~ label,
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  input:focus
  ~ label,
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  textarea:focus
  ~ label {
  font-size: 15px;
  color: #343434;
  top: 3px;
  left: 11px;
  padding: 0;
  transition: all 0.125s cubic-bezier(0.2, 0, 0.03, 1);
}
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  .styled-input {
  float: left;
  width: 100%;
  position: relative;
  top: 5px;
}
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  .styled-input
  label {
  color: #999;
  padding: 1rem;
  position: absolute;
  top: -10px;
  left: 0;
  transition: all 0.25s cubic-bezier(0.2, 0, 0.03, 1);
  pointer-events: none;
}
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  .styled-input.wide {
  width: 100%;
}
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  input,
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  textarea {
  padding: 1rem 1rem;
  border: 0;
  width: 100%;
  font-size: 1rem;
}
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  input:focus,
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  textarea:focus {
  height: 47px;
  margin-top: 16px;
  outline: 0;
}
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  input:focus
  ~ .searchBookBtmInput,
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  textarea:focus
  ~ .searchBookBtmInput {
  height: 47px;
  margin-top: 16px;
  transition: all 0.125s cubic-bezier(0.2, 0, 0.03, 1);
}
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  input:valid,
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  textarea:valid {
  height: 37px;
  margin-top: 16px;
}
.bookingFomrBox .searchBookBtmRow .searchBookBtmLocation .searchToggleBtnDv {
  width: auto;
  position: relative;
}
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchToggleBtnDv
  .searchToggleBtn {
  width: 30px;
  height: 30px;
  min-width: 30px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
.bookingFomrBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchToggleBtnDv:before {
  width: 1px;
  height: 73px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -24px;
  background-color: #e5e5e5;
  content: "";
}
.bookingFomrBox .SearcBookMultiCity {
  width: 100%;
  height: auto;
  float: left;
}
.bookingFomrBox .SearcBookMultiCity .SearcBookMultiCityRow {
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 12px;
  align-items: center;
  border: 0.5px solid #cccccc;
  border-radius: 10px;
  overflow: hidden;
}
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox {
  width: 100%;
  height: 67px;
}
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  input:valid
  ~ label,
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  textarea:valid
  ~ label,
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  input:focus
  ~ label,
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  textarea:focus
  ~ label,
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  input:focus
  ~ label,
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  textarea:focus
  ~ label,
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  input:valid
  ~ label,
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  .searchBookBtmRow
  .searchBookBtmLocation
  .searchBookBtmBox
  textarea:valid
  ~ label {
  font-size: 15px;
  color: #343434;
  top: 3px;
  left: 11px;
  padding: 0;
  transition: all 0.125s cubic-bezier(0.2, 0, 0.03, 1);
}
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  .styled-input {
  float: left;
  width: 100%;
  position: relative;
}
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  .styled-input
  label {
  color: #999;
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.25s cubic-bezier(0.2, 0, 0.03, 1);
  pointer-events: none;
}
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  .styled-input.wide {
  width: 100%;
}
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  input,
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  textarea {
  padding: 1rem 1rem;
  border: 0;
  width: 100%;
  font-size: 1rem;
}
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  input:focus,
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  textarea:focus {
  height: 47px;
  margin-top: 16px;
  outline: 0;
}
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  input:focus
  ~ .searchBookBtmInput,
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  textarea:focus
  ~ .searchBookBtmInput {
  height: 47px;
  margin-top: 16px;
  transition: all 0.125s cubic-bezier(0.2, 0, 0.03, 1);
}
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  input:valid,
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox
  textarea:valid {
  height: 47px;
  margin-top: 16px;
}
.bookingFomrBox
  .SearcBookMultiCity
  .SearcBookMultiCityRow
  .SearcBookMultiCityBox:nth-child(2) {
  border-right: 1px #ccc solid;
  border-left: 1px #ccc solid;
}
.bookingFomrBox .SearcBookMultiCityRowBtn {
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 12px;
  align-items: center;
  justify-content: space-between;
}
.bookingFomrBox .SearcBookMultiCityRowBtn .addFlightBtn {
  background-color: #bce6f1;
  padding: 18px 30px;
  font-size: 16px;
  border-radius: 25px;
  display: inline-block;
  text-decoration: none;
  color: #242424;
}
.bookingFomrBox .SearcBookMultiCityRowBtn .addFlightBtn:hover {
  background-color: #00b6ea;
  color: #fff;
}
.bookingFomrBox .searchBookDateBox {
  width: 20%;
  height: auto;
  float: left;
  background: #ffffff;
  border: 0.5px solid #cccccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding-top: 5px;
  min-height: 73px;
}
.bookingFomrBox .searchBookDateBox .searchBookBtmName {
  width: 100%;
  height: auto;
  float: left;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #000;
  padding-left: 10px;
  padding-bottom: 5px;
}
.bookingFomrBox .searchBookDateBox .searchBookBtmInput {
  width: 100%;
  height: 40px;
  margin-top: -5px;
  margin-bottom: 5px;
  padding-left: 10px;
  border: 0;
  outline: none;
}
.bookingFomrBox .searchBookDateBox .searchBookBtmBtn {
  color: #242424;
  font-size: 16px;
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 8px;
  width: 94%;
  height: 100%;
  padding-top: 31px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bookingFomrBox .searchBookDateBox .searchBookBtmBtn:after {
  display: none;
}
.bookingFomrBox .passengerSelectBox .dropdown-menu {
  min-width: 300px;
  border: 0;
  padding: 15px;
  background-color: #fff;
  min-height: 100px;
  box-shadow: 0px 0px 10px #e5e5e5;
}
.bookingFomrBox .passengerSelectBox .dropdown-menu .passengerCountAddBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.bookingFomrBox
  .passengerSelectBox
  .dropdown-menu
  .passengerCountAddBox
  div
  span {
  font-size: 13px;
  color: #666;
  width: 100%;
  display: inline-block;
}
.bookingFomrBox
  .passengerSelectBox
  .dropdown-menu
  .passengerCountAddBox
  .passengerCountBx {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}
.bookingFomrBox
  .passengerSelectBox
  .dropdown-menu
  .passengerCountAddBox
  .passengerCountBx
  .passengerCountbtn {
  width: 34px;
  height: 34px;
  box-shadow: none;
  border: 2px solid #e5e5e5;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #242424;
  font-size: 22px;
  cursor: pointer;
}
.bookingFomrBox
  .passengerSelectBox
  .dropdown-menu
  .passengerCountAddBox
  .passengerCountBx
  input {
  width: 40px;
  border: 0;
  text-align: center;
  font-size: 18px;
  outline: none;
}
.bookingFomrBox .passengerSelectBox .dropdown-menu .FlightClassBook {
  -webkit-appearance: revert;
  -moz-appearance: revert;
  appearance: revert;
  height: 50px;
}
.bookingFomrBox .passengerSelectBox .dropdown-menu .filterDoneBtn {
  width: 100%;
  height: 50px;
  background-color: #d1af77;
  text-align: center;
  color: #fff;
  min-width: 100px;
  box-shadow: 0px 4px 14px rgba(59, 157, 185, 0.7);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.2s ease;
  font-family: "poppinsmedium";
  margin-top: 10px;
}
.bookingFomrBox .passengerSelectBox .dropdown-menu .filterDoneBtn a {
  color: #fff;
  text-decoration: none;
}
.bookingFomrBox .passengerSelectBox .dropdown-menu .filterDoneBtn:hover {
  background-color: #002d3a;
}
.bookingFomrBox .searchBookBoxBtn {
  width: 15%;
  height: 73px;
  background-color: #d1af77;
  text-align: center;
  color: #fff;
  min-width: 100px;
  box-shadow: 0px 4px 14px 0px 4px 14px rgb(255 235 201);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.2s ease;
  font-family: "poppinsmedium";
  border: 0;
}
.bookingFomrBox .searchBookBoxBtn:hover {
  background-color: #4a391e;
}
.bookingFomrBox .searchBookBoxBtn a {
  color: #fff;
  text-decoration: none;
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.homeFeatureSection {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 30px 0;
  overflow: hidden;
}
.homeFeatureSection .homeFeatureBox {
  width: 100%;
  height: auto;
  margin: auto;
  background: #ffffff;
  /* box-shadow: 8px 9px 114px rgba(0, 0, 0, 0.08); */
  border-radius: 20px;
  padding: 30px 20px;
  position: relative;
}
.homeFeatureSection .homeFeatureBox::before {
  position: absolute;
  content: "";
  background-image: url(./assets/images/flight-vector-ico.png);
  width: 240px;
  height: 190px;
  left: -110px;
  top: 20px;
  background-size: cover;
  z-index: -1;
}
.homeFeatureSection .homeFeatureBox::after {
  position: absolute;
  content: "";
  background-image: url(./assets/images/flight-vector-ico.png);
  width: 240px;
  height: 190px;
  right: -160px;
  top: -20px;
  background-size: cover;
  z-index: -1;
}
.homeFeatureSection .homeFeatureBox .homeFeatureBoxCnt {
  width: 100%;
  height: auto;
  float: left;
}
.homeFeatureSection .homeFeatureBox .homeFeatureBoxCnt .homeFeatureBoxIco {
  width: 100%;
  text-align: center;
  float: left;
  margin-bottom: 20px;
}
.homeFeatureSection .homeFeatureBox .homeFeatureBoxCnt .homeFeatureBoxIco img {
  max-height: 90px;
}
.homeFeatureSection .homeFeatureBox .homeFeatureBoxCnt .homeFeatureBoxHead {
  width: 100%;
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #222222;
  text-align: center;
}
.homeFeatureSection .homeFeatureBox .homeFeatureBoxCnt .homeFeatureBoxHead h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #222222;
}
.homeFeatureSection .homeFeatureBox .homeFeatureBoxCnt .homeFeatureBoxContent {
  width: 100%;
  float: left;
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #595959;
  margin-bottom: 15px;
}
.homeFeatureSection .homeFeatureBox .cntrBoxFtr {
  /* border-left: 1px #e5e5e5 solid; */
  /* border-right: 1px #e5e5e5 solid; */
}

.exploreTopDestination {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 50px 0;
  background-image: url(./assets/images/top_destination_bg.jpg);
  background-size: cover;
}
.exploreTopDestination .exploreDstLeftSec {
  width: 100%;
  height: auto;
  float: left;
}
.exploreTopDestination .exploreDstLeftSec h3 {
  width: 100%;
  float: left;
  font-weight: 400;
  font-size: 29px;
  line-height: 39px;
  color: #002d3a;
}
.exploreTopDestination .exploreDstLeftSec h3 span {
  width: 100%;
  float: left;
}
.exploreTopDestination .exploreDstLeftSec p {
  width: 100%;
  float: left;
  color: #595959;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
}
.exploreTopDestination .topDestinationScroll {
  width: 100%;
  height: auto;
  float: left;
}
.exploreTopDestination .topDestinationScroll .topDestinationScrollBox {
  width: 100%;
  height: auto;
  float: left;
}
.exploreTopDestination
  .topDestinationScroll
  .topDestinationScrollBox
  .topDestinationScrollImg {
  width: 100%;
  height: auto;
  float: left;
  border-radius: 10px;
  overflow: hidden;
}
.exploreTopDestination
  .topDestinationScroll
  .topDestinationScrollBox
  .topDestinationScrollImg
  img {
  width: 100%;
  transition: 0.2s ease;
}
.exploreTopDestination
  .topDestinationScroll
  .topDestinationScrollBox
  .topDestinationScrollImg:hover
  img {
  transform: scale(1.1);
}
.exploreTopDestination
  .topDestinationScroll
  .topDestinationScrollBox
  .topDestinationScrollCntbx {
  width: 100%;
  height: auto;
  float: left;
  margin-top: 15px;
}
.exploreTopDestination
  .topDestinationScroll
  .topDestinationScrollBox
  .topDestinationScrollCntbx
  strong {
  width: 100%;
  height: auto;
  float: left;
  font-weight: 500;
  font-size: 25px;
  line-height: 20px;
  color: #0d0d0d;
  margin-bottom: 8px;
}
.exploreTopDestination
  .topDestinationScroll
  .topDestinationScrollBox
  .topDestinationScrollCntbx
  p {
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  color: #424242;
}
.exploreTopDestination .topDestinationScroll .owl-next {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  right: -22px;
  top: 35%;
  background: #ffffff !important;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}
.exploreTopDestination .topDestinationScroll .owl-next span {
  font-size: 27px;
}
.exploreTopDestination .topDestinationScroll .owl-prev {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  left: -22px;
  top: 35%;
  background: #ffffff !important;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}
.exploreTopDestination .topDestinationScroll .owl-prev span {
  font-size: 27px;
}

.viewAllBt {
  width: auto;
  display: inline-block;
  padding: 10px 30px;
  background: #ffffff;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
  border-radius: 50px;
  color: #000;
  font-size: 17px;
  text-decoration: none;
}

.exploreTopCountriesSec {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 80px 0;
  background-image: url(./assets/images/top_countries_bg.jpg);
  background-size: 1500px;
  background-repeat: no-repeat;
  background-position: center 80px;
}
.exploreTopCountriesSec h3 {
  width: 100%;
  float: left;
  font-weight: 400;
  font-size: 29px;
  line-height: 39px;
  color: #002d3a;
  text-align: center;
}
.exploreTopCountriesSec .exploreTopCountriesRow {
  width: 100%;
  height: auto;
  float: left;
  margin-top: 30px;
}
.exploreTopCountriesSec .exploreTopCountriesRow .exploreTopCountriesBox {
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 15px;
  background: #ffffff;
  box-shadow: -3px 9px 44px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: 0.2s ease;
}
.exploreTopCountriesSec .exploreTopCountriesRow .exploreTopCountriesBox:hover {
  box-shadow: -3px 9px 44px rgba(0, 0, 0, 0.09);
  transform: translateY(-5px);
}
.exploreTopCountriesSec .exploreTopCountriesRow .exploreTopCountriesBox a {
  width: 100%;
  height: 100%;
  display: inline-block;
  text-decoration: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #000;
}
.exploreTopCountriesSec .exploreTopCountriesRow .exploreTopCountriesBox a span {
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  color: #424242;
  display: inline-block;
}

.topCitiesSec {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 40px 0;
}
.topCitiesSec .topCitiesHead {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.topCitiesSec .topCitiesHead h3 {
  width: 100%;
  float: left;
  font-weight: 400;
  font-size: 29px;
  line-height: 39px;
  color: #002d3a;
}
.topCitiesSec .topCitiesHead .viewAllBtn2 {
  font-weight: 400;
  font-size: 17px;
  color: #242424;
  text-decoration: none;
  white-space: nowrap;
}
.topCitiesSec .topCitiesCntBox {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
  margin-bottom: 25px;
}
.topCitiesSec .topCitiesCntBox .topCitiesCntBoximg {
  width: 100%;
  height: auto;
  float: left;
  border-radius: 10px;
  overflow: hidden;
  max-height: 273px;
}
.topCitiesSec .topCitiesCntBox .topCitiesCntBoximg img {
  width: auto;
  transition: 0.2s ease;
}
.topCitiesSec .topCitiesCntBox .topCitiesCntBoximg:hover img {
  transform: scale(1.1);
}
.topCitiesSec .topCitiesCntBox .topCitiesCntentBx {
  width: 100%;
  height: auto;
  float: left;
  margin-top: 15px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 12.96%, #000000 100%);
  border-radius: 10px;
  padding: 15px;
}
.topCitiesSec .topCitiesCntBox .topCitiesCntentBx strong {
  width: 100%;
  height: auto;
  float: left;
  font-weight: 500;
  font-size: 25px;
  line-height: 20px;
  color: #fff;
  margin-bottom: 8px;
}
.topCitiesSec .topCitiesCntBox .topCitiesCntentBx p {
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
}
.topCitiesSec{
  .col-md-6{
    .col-md-6{
      .topCitiesCntBox{
        .topCitiesCntBoximg{
          max-height: 255px;
        }
      }
    }
  }
}
.topCitiesSec{
  .col-md-6{
    .col-md-12{
      .topCitiesCntBox{
        .topCitiesCntBoximg{
          max-height: 290px;
        }
      }
    }
  }
}

.round-button {
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  height:50px;
  border: none;
  border-radius: 50%;
  background-color: #fff; /* Choose your desired background color */
  color: #000000; /* Choose your desired text color */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a box shadow for a raised effect */
}

.round-button:hover {
  background-color: #0056b3; /* Change the background color on hover */
}



.airelineCompaniesSc {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 40px 0;
}
.airelineCompaniesSc h3 {
  width: 100%;
  float: left;
  font-weight: 400;
  font-size: 29px;
  line-height: 39px;
  color: #002d3a;
  text-align: center;
  margin-bottom: 20px;
}
.airelineCompaniesSc .airelineCompaniesSrlSec {
  width: 100%;
  height: auto;
  display: inline-block;
}
.airelineCompaniesSc .airelineCompaniesSrlSec .airelineCompaniesBox {
  width: 100%;
  height: 100px;
  text-align: center;
  border: solid 1px #e5e5e5;
  padding: 10px;
  display: inline-block;
}
.airelineCompaniesSc .airelineCompaniesSrlSec .airelineCompaniesBox img {
  filter: grayscale(1);
  height: 100%;
}

.owl-dots {
  text-align: center;
  padding-top: 15px;
}

.owl-dots button.owl-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #ccc;
  margin: 0 3px;
}

.owl-dots button.owl-dot.active {
  background-color: #666;
}

.owl-dots button.owl-dot:focus {
  outline: none;
}

.cancellationProtectionSec {
  width: 100%;
  display: inline-block;
  padding: 40px 0;
}
.cancellationProtectionSec .cancellationProtectionbox {
  width: 100%;
  background: #f1f6fb;
  border-radius: 50px;
  display: inline-block;
  padding: 20px;
}
.cancellationProtectionSec .cancellationProtectionbox .cancellationCntLft {
  width: 100%;
  display: inline-block;
}
.cancellationProtectionSec .cancellationProtectionbox .cancellationCntLft h4 {
  width: 100%;
  float: left;
  font-weight: 400;
  font-size: 29px;
  line-height: 39px;
  color: #002d3a;
  margin-bottom: 20px;
}
.cancellationProtectionSec .cancellationProtectionbox .cancellationCntLft p {
  width: 100%;
  float: left;
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.04em;
  color: #595959;
  margin-bottom: 15px;
}
.cancellationProtectionSec
  .cancellationProtectionbox
  .cancellationCntLft
  .cancellationCntLftPointsRow {
  width: 100%;
  display: Flex;
  gap: 20px;
}
.cancellationProtectionSec
  .cancellationProtectionbox
  .cancellationCntLft
  .cancellationCntLftPointsRow
  .cancellationCntLftPointsBox {
  width: auto;
  background: rgba(255, 255, 255, 0.4941176471);
  border-radius: 50px;
  position: relative;
  color: #2d3b3e;
  font-size: 16px;
  display: flex;
  margin-top: 20px;
  align-items: center;
  padding-right: 15px;
  padding-top: 4px;
}
.cancellationProtectionSec
  .cancellationProtectionbox
  .cancellationCntLft
  .cancellationCntLftPointsRow
  .cancellationCntLftPointsBox
  .cancellationCntLftPointsBoxIco {
  width: 50px;
  height: 50px;
}
.cancellationProtectionSec
  .cancellationProtectionbox
  .cancellationCntLft
  .cancellationCntLftPointsRow
  .cancellationCntLftPointsBox
  .cancellationCntLftPointsBoxIco
  img {
  max-width: 100% !important;
}
.cancellationProtectionSec .cancellationProtectionbox .cancellationImgRight {
  width: 100%;
  display: inline-block;
}
.cancellationProtectionSec
  .cancellationProtectionbox
  .cancellationImgRight
  img {
  width: 100%;
}

/**************Footer style start**************/
.footerSection {
  width: 100%;
  display: inline-block;
  padding-top: 50px;
    background-image: url(./assets/images/footer_bg.jpg); 
    background-size: cover;
  position: relative;
}
.footerSection .footerBox {
  width: 100%;
  height: auto;
  float: left;
}
.footerSection .footerBox .footerBoxHead {
  width: 100%;
  height: auto;
  float: left;
  font-weight: 400;
  font-size: 22px;
  line-height: 46px;
  color: #000;
  margin-bottom: 20px;
}
.footerSection .footerBox .footerBoxHead img {
  max-width: 250px;
}
.footerSection .footerBox .footerBoxCnt {
  width: 100%;
  height: auto;
  float: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 42px;
  color: #2d2d2d;
}
.footerSection .footerBox .footerBoxCnt ul {
  padding: 0;
}
.footerSection .footerBox .footerBoxCnt ul li {
  width: 100%;
  height: auto;
  float: left;
  font-weight: 400;
  font-size: 15px;
  line-height: 35px;
  color: #2d2d2d;
  list-style: none;
  transition: 0.2s ease;
}
.footerSection .footerBox .footerBoxCnt ul li:hover {
  color: #767733;
}
.footerSection .footerBox .footerSocialSec {
  width: 100%;
  height: auto;
  display: flex;
  gap: 15px;
}
.footerSection .footerBox .footerSocialSec .footerSocialBox {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  min-width: 30px;
}
.footerSection .footerBox .footerSocialSec .footerSocialBox img {
  width: 100%;
}
.footerSection .footerCopyRight {
  width: 100%;
  height: auto;
  display: inline-block;
  /* background-color: #fff; */
  padding: 3px 0;
  margin-top: 50px;
  border-top: 1px #e5e5e5 solid;
}
.footerSection .footerCopyRight .footerCopyRightCnt {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #676767;
  justify-content: space-between;
}
.footerSection .footerCopyRight .footerCopyRightCnt .footerCopyRightIatLg {
  width: 100px;
  height: auto;
  display: inline-block;
}
.footerSection .footerCopyRight .footerCopyRightCnt .footerCopyRightIatLg img {
  width: 100%;
}
.footerSection .footerCopyRight .footerCopyRightCnt .DesgnDevelop {
  text-align: right;
}

/**************Footer style end**************/
/*********** Search page style***********/
.searchBannersec {
  width: 100%;
  height: auto;
  display: inline-block;
  position: relative;
  padding: 80px;
  background-image: url(./assets/images/search_banner.jpg);
  background-repeat: no-repeat;
  margin-top: 74px;
  background-size: cover;
}
.searchBannersecMob {
  display: block;
}
.searchBannersec .bookingFomrBox {
  margin-top: 0;
}

.searchPageFlightSelectSection {
  width: 100%;
  height: auto;
  float: left;
  padding: 70px 0 40px 0;
}
.searchPageFlightSelectSection .searchPageFlightSelectSec {
  width: 100%;
  height: auto;
  float: left;
  align-items: center;
  padding: 10px;
}
.searchPageFlightSelectSection .searchPageFlightSelectSec .searchPageFlightbox {
  width: 100%;
  height: auto;
  padding: 10px;
  padding-left: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  position: relative;
  display: inline-block;
  margin: 10px 0;
  cursor: pointer;
}
.searchPageFlightSelectSection
  .searchPageFlightSelectSec
  .searchPageFlightbox
  .FlightSelectFltr {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 10px;
  top: 10px;
  border: solid 1px #e5e5e5;
  opacity: 0.5;
}
.searchPageFlightSelectSection
  .searchPageFlightSelectSec
  .searchPageFlightbox
  .FlightSelectCnt {
  width: 100%;
  height: auto;
  float: left;
}
.searchPageFlightSelectSection
  .searchPageFlightSelectSec
  .searchPageFlightbox
  .FlightSelectCnt
  img {
  max-width: 100%;
  height: 40px;
  width: auto;
}
.searchPageFlightSelectSection
  .searchPageFlightSelectSec
  .searchPageFlightbox
  .FlightSelectCnt
  span {
  width: 100%;
  height: auto;
  float: left;
  font-size: 14px;
  color: #8b8b8b;
}
.searchPageFlightSelectSection .owl-carousel .owl-item {
  padding: 0 10px;
}
.searchPageFlightSelectSection .owl-next {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  right: -15px;
  top: 30%;
  background: #ffffff !important;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}
.searchPageFlightSelectSection .owl-next span {
  font-size: 27px;
}
.searchPageFlightSelectSection .owl-prev {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  left: -15px;
  top: 30%;
  background: #ffffff !important;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}
.searchPageFlightSelectSection .owl-prev span {
  font-size: 27px;
}

.searchViewCntSection {
  width: 100%;
  display: inline-block;
  padding-bottom: 40px;
}
.searchViewCntSection .SearchPageLeftFilterSection {
  width: 100%;
  display: inline-block;
  background: #ffffff;
  /* box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05); */
  border-radius: 15px;
  padding: 10px;
  position: relative;
  /*hide the outline behind the border*/
}
.searchViewCntSection .SearchPageLeftFilterSection .accordion-item {
  border: 0;
  border-bottom: 1px #e5e5e5 solid;
}
.searchViewCntSection
  .SearchPageLeftFilterSection
  .accordion-button:not(.collapsed) {
  color: #000;
  border: 0;
  background-color: transparent;
  box-shadow: none;
}
.searchViewCntSection .SearchPageLeftFilterSection .accordion-button {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #112211;
  padding: 13px 10px;
}
.searchViewCntSection .SearchPageLeftFilterSection .accordion-item:last-child {
  border: 0 !important;
}
.searchViewCntSection .SearchPageLeftFilterSection .accordion-body {
  padding: 10px;
}
.searchViewCntSection .SearchPageLeftFilterSection .SearchPageFilterSelect {
  width: 100%;
  height: auto;
  padding: 12px 10px;
  border: solid 1px #d7d7d7;
  border-radius: 6px;
  color: #242424;
  font-size: 15px;
}
.searchViewCntSection .SearchPageLeftFilterSection .range-slider {
  width: 100%;
  display: inline-block;
  text-align: center;
  position: relative;
  margin-bottom: 10px;
}
.searchViewCntSection .SearchPageLeftFilterSection .range-slider .rangeValues {
  display: block;
}
.searchViewCntSection .SearchPageLeftFilterSection .minRange[type="range"] {
  border: 1px solid white;
  width: 100%;
  position: absolute;
  left: 0;
}
.searchViewCntSection
  .SearchPageLeftFilterSection
  .minRange[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}
.searchViewCntSection
  .SearchPageLeftFilterSection
  .minRange[type="range"]::-webkit-slider-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #21c1ff;
  margin-top: -4px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.searchViewCntSection
  .SearchPageLeftFilterSection
  .minRange[type="range"]:focus {
  outline: none;
}
.searchViewCntSection
  .SearchPageLeftFilterSection
  .minRange[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}
.searchViewCntSection
  .SearchPageLeftFilterSection
  .minRange[type="range"]::-moz-range-track {
  width: 300px;
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}
.searchViewCntSection
  .SearchPageLeftFilterSection
  .minRange[type="range"]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #21c1ff;
}
.searchViewCntSection
  .SearchPageLeftFilterSection
  .minRange[type="range"]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}
.searchViewCntSection
  .SearchPageLeftFilterSection
  .minRange[type="range"]::-ms-track {
  width: 300px;
  height: 5px;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;
  /*remove default tick marks*/
  color: transparent;
  z-index: -4;
}
.searchViewCntSection
  .SearchPageLeftFilterSection
  .minRange[type="range"]::-ms-fill-lower {
  background: #777;
  border-radius: 10px;
}
.searchViewCntSection
  .SearchPageLeftFilterSection
  in.minRangeput[type="range"]::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px;
}
.searchViewCntSection
  .SearchPageLeftFilterSection
  .minRange[type="range"]::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #21c1ff;
}
.searchViewCntSection
  .SearchPageLeftFilterSection
  .minRange[type="range"]:focus::-ms-fill-lower {
  background: #888;
}
.searchViewCntSection
  .SearchPageLeftFilterSection
  .minRange[type="range"]:focus::-ms-fill-upper {
  background: #ccc;
}

.filterSelctBoxRow {
  width: 100%;
  height: auto;
  display: inline-block;
  position: relative;
  padding: 5px 0;
  font-size: 14px;
  color: #242424;
  letter-spacing: 1px;
}
.filterSelctBoxRow input[type="checkbox"] {
  margin-right: 5px;
}
.filterSelctBoxRow span {
  float: right;
  font-size: 13px;
  color: #8b8b8b;
}

.dprtArvltimeSelectSec {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.dprtArvltimeSelectSec .dprtArvltimeSelectbox {
  width: 100%;
  height: auto;
  padding: 7px;
  text-align: center;
  border: solid 1px #dddddd;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
}
.dprtArvltimeSelectSec .dprtArvltimeSelectbox input {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.dprtArvltimeSelectSec .dprtArvltimeSelectbox:hover {
  border: solid 1px #d1af77;
}
.dprtArvltimeSelectSec .dprtArvltimeSelectbox .dprtArvltimeSelectboxIco {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  float: left;
  text-align: center;
}
.dprtArvltimeSelectSec .dprtArvltimeSelectbox .dprtArvltimeSelectboxIco img {
  height: 20px;
}
.dprtArvltimeSelectSec .dprtArvltimeSelectbox .dprtArvltimeSelectboxCnt {
  width: 100%;
  text-align: center;
  float: left;
}
.dprtArvltimeSelectSec .dprtArvltimeSelectbox .dprtArvltimeSelectboxCnt strong {
  width: 100%;
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  color: #242424;
  font-weight: 500;
}
.dprtArvltimeSelectSec .dprtArvltimeSelectbox .dprtArvltimeSelectboxCnt span {
  width: 100%;
  display: inline-block;
  font-size: 12px;
  color: #5a5a5a;
}
.dprtArvltimeSelectSec .active {
  border: solid 1px #d1af77;
}

.FlightSearchpageRightSec {
  width: 100%;
  display: inline-block;
  background: #ffffff;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 10px;
}
.FlightSearchpageRightSec .FlightSearchpageRighttopSort {
  width: 100%;
  height: auto;
  float: left;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  box-shadow: 0px 4px 4px #f7f7f7;
  margin-bottom: 30px;
}
.FlightSearchpageRightSec
  .FlightSearchpageRighttopSort
  .FlightSearchpageRighttopSortBox {
  width: 100%;
  height: auto;
  float: left;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #112211;
  cursor: pointer;
  border-right: 1px #e5e5e5 solid;
}
.FlightSearchpageRightSec
  .FlightSearchpageRighttopSort
  .FlightSearchpageRighttopSortBox
  strong {
  width: 100%;
  height: auto;
  float: left;
  color: #0f210f;
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  margin: 5px 0;
}
.FlightSearchpageRightSec
  .FlightSearchpageRighttopSort
  .FlightSearchpageRighttopSortBox
  span {
  width: 100%;
  height: auto;
  float: left;
  font-weight: 500;
  font-size: 13px;
  color: #8b8b8b;
}
.FlightSearchpageRightSec
  .FlightSearchpageRighttopSort
  .FlightSearchpageRighttopSortBox:last-child {
  border: 0;
}
.FlightSearchpageRightSec .FlightSearchpageRighttopSort .active {
  position: relative;
  border: 0;
}
.FlightSearchpageRightSec .FlightSearchpageRighttopSort .active::before {
  width: 100%;
  height: 2px;
  background-color: #d1af77;
  position: absolute;
  left: 0;
  content: "";
  bottom: -10px;
}
.FlightSearchpageRighttopSort li .nav-link {
  color: #666;
}

.FlightSearchResultBoxSec {
  width: 100%;
  height: auto;
  display: inline-block;
  position: relative;
}
.FlightSearchResultBoxSec .FlightSearchResultBox {
  width: 100%;
  height: auto;
  float: left;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 20px;
  padding: 0px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl {
  width: 100%;
  height: auto;
  float: left;
  padding-top: 0;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow1 {
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background-color: #f0f1f1;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow1
  .FlightSearchResultBoxFirstDtlRow1LCDprt {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 16px;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow1
  .FlightSearchResultBoxFirstDtlRow1LCDprt
  .FlightResultDprtDtl {
  display: flex;
  align-items: center;
  gap: 15px;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow1
  .FlightSearchResultBoxFirstDtlRow1LCDprt
  strong {
  color: #000;
  font-weight: 600;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow1
  .FlightSearchResultBoxFirstDtlRow1LCDprt
  .arrowIco {
  position: relative;
  top: -3px;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2 {
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  padding: 11px;
  border-bottom: 1px #e5e5e5 solid;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2:last-child {
  border: 0;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchResultBoxFirstDtlRow2Lftdtl {
  display: flex;
  align-items: center;
  flex: 1 1;
  grid-gap: 10px;
  gap: 10px;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchResultBoxFirstDtlRow2Lftdtl
  .FlightSearchRightSelectInpt {
  max-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchResultBoxFirstDtlRow2Lftdtl
  .FlightSearchRightSelectInpt
  input {
  width: 18px;
  height: 18px;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchResultBoxFirstDtlRow2Lftdtl
  .FlightSearchRightFlight {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 0.8 1;
  text-align: center;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchResultBoxFirstDtlRow2Lftdtl
  .FlightSearchRightFlight
  .FlightSearchRightFlightImg {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  overflow: visible;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchResultBoxFirstDtlRow2Lftdtl
  .FlightSearchRightFlight
  .FlightSearchRightFlightImg
  img {
  max-width: 80px;
  max-height: 80px;
  width: 100%;
  padding: 5px;
  mix-blend-mode: darken;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchResultBoxFirstDtlRow2Lftdtl
  .FlightSearchRightFlight
  span {
  width: 100%;
  height: auto;
  float: left;
  font-size: 14px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #868686;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchResultBoxFirstDtlRow2Lftdtl
  .FlightRightDepartureBox {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  flex: 0.75 1;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchResultBoxFirstDtlRow2Lftdtl
  .FlightRightDepartureBox
  strong {
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  color: #000;
  display: inline-block;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchResultBoxFirstDtlRow2Lftdtl
  .FlightRightDepartureBox
  span {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  color: #868686;
  display: inline-block;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchResultBoxFirstDtlRow2Lftdtl
  .FlightRightDepartureBox:last-child {
  text-align: right;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchResultBoxFirstDtlRow2Lftdtl
  .FlightSearchTtlDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1.5 1;
  text-align: center;
  padding: 0 10px;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchResultBoxFirstDtlRow2Lftdtl
  .FlightSearchTtlHour {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: 13px;
  color: #868686;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchResultBoxFirstDtlRow2Lftdtl
  .FlightSearchTtlimg {
  width: 100%;
  height: 3px;
  background-color: #29557e;
  margin: 10px 0;
  display: inline-block;
  max-width: 300px;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchResultBoxFirstDtlRow2Lftdtl
  .FlightSearchTtlimg
  img {
  width: 100%;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchResultBoxFirstDtlRow2Lftdtl
  .FlightSearchTtlStop {
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: 13px;
  color: #868686;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchDtailBx {
  display: flex;
  justify-content: end;
  flex-direction: column;
  margin-left: 10px;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchDtailBx
  span {
  width: 100%;
  text-align: right;
  font-size: 13px;
  color: #00b67a;
  position: relative;
  top: -10px;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchDtailBx
  .FlightSearchDtailBtn {
  width: 100%;
  height: 40px;
  text-align: center;
  background: #dfd0b9;
  border-radius: 30px;
  display: inline-block;
  color: #503e1f;
  font-size: 14px;
  line-height: 40px;
  text-decoration: none;
  transition: 0.2s ease;
  cursor: pointer;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchDtailBx
  .FlightSearchDtailBtn
  i {
  position: relative;
  top: 1px;
  transition: 0.2s ease;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .FlightSearchResultBoxFirstDtlRow2
  .FlightSearchDtailBx
  .FlightSearchDtailBtn:hover {
  background-color: #d1af77;
  color: #fff;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .flightSearchMoreDtl {
  width: 100%;
  padding: 10px;
  float: left;
  border-radius: 10px;
  background-color: aliceblue;
  /* display: none; */
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .flightSearchMoreDtl
  .flightSearchMoreDtlFlightDtl {
  width: 100%;
  height: auto;
  float: left;
  display: flex;
  align-items: center;
  gap: 10px;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .flightSearchMoreDtl
  .flightSearchMoreDtlFlightDtl
  .flightSearchMoreDtlFlightDtlImageSc {
  max-width: 150px;
  display: inline-block;
  text-align: left;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .flightSearchMoreDtl
  .flightSearchMoreDtlFlightDtl
  .flightSearchMoreDtlFlightDtlTxt {
  display: inline-block;
  font-size: 18px;
  color: #242424;
  line-height: 22px;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .flightSearchMoreDtl
  .flightSearchMoreDtlFlightDtl
  .flightSearchMoreDtlFlightDtlTxt
  span {
  width: 100%;
  display: inline-block;
  font-size: 14px;
  color: #666;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .flightSearchMoreDtl
  .FlightSearchDtlTermnlDtl {
  width: 95%;
  height: auto;
  padding: 10px;
  position: relative;
  padding-left: 20px;
  display: inline-block;
  margin-top: 20px;
  margin-left: 5%;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .flightSearchMoreDtl
  .FlightSearchDtlTermnlDtl::before {
  content: "";
  content: "";
  left: -9px;
  top: 0;
  width: 18px;
  height: 18px;
  background-color: #956513;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .flightSearchMoreDtl
  .FlightSearchDtlTermnlDtl::after {
  content: "";
  left: -9px;
  bottom: 0;
  width: 18px;
  height: 18px;
  background-color: #956513;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .flightSearchMoreDtl
  .FlightSearchDtlTermnlDtl
  .FlightSearchDtlTermnlDtlLine {
  width: 1px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #ccc;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .flightSearchMoreDtl
  .FlightSearchDtlTermnlDtl
  .FlightSearchDtlTermnlDtlTimeLoc {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: #000;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .flightSearchMoreDtl
  .FlightSearchDtlTermnlDtl
  .FlightSearchDtlTermnlDtlTimeLoc
  span {
  width: 158px;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .flightSearchMoreDtl
  .FlightSearchDtlTermnlDtl
  .FlightSearchDtlTermnlDtlTimeLoc
  span
  small {
  width: 100%;
  font-size: 14px;
  color: #666;
  display: inline-block;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .flightSearchMoreDtl
  .FlightSearchDtlTermnlDtl
  .FlightSrchDtlSub2 {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .flightSearchMoreDtl
  .FlightSearchDtlTermnlDtl
  .FlightSrchDtlSub2
  div {
  width: 158px;
  display: inline-block;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl
  .flightSearchMoreDtl
  .FlightSearchDtlTermnlDtl
  .FlightSrchDtlSub2
  span {
  width: auto;
  display: inline-block;
  padding: 5px 10px;
  color: #242424;
  font-size: 14px;
  background-color: #c6e4fb;
  margin-bottom: 5px;
  border-radius: 10px;
}
.FlightSearchResultBoxSec .FlightSearchResultBox .flightSearchMoreDtl {
  display: block;
}
.FlightSearchResultBoxSec .FlightSearchResultBox .FlightSearchDtailBtn i {
  transform: rotate(180deg);
}
.FlightSearchResultBoxSec .FlightSearchResultBox .FlightSearchConfirmSection {
  width: 100%;
  background-color: #fffbf5;
  border-radius: 0px 0px 20px 20px;
  display: inline-block;
  padding: 15px;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchConfirmSection
  .FlightSearchConfirmSectionRow1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchConfirmSection
  .FlightSearchConfirmSectionRow1
  .FlightSearchTtlAmount {
  width: 100%;
  display: inline-block;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchConfirmSection
  .FlightSearchConfirmSectionRow1
  .FlightSearchTtlAmount
  strong {
  width: 100%;
  display: inline-block;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchConfirmSection
  .FlightSearchConfirmSectionRow1
  .FlightSearchTtlAmount
  strong
  .perperson {
  font-size: 16px;
  font-weight: 500;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchConfirmSection
  .FlightSearchConfirmSectionRow1
  .FlightSearchTtlAmount
  .totalAmount {
  width: 100%;
  display: inline-block;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #3e3e3e;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchConfirmSection
  .FlightSearchConfirmSectionRow1
  .confirmSecBtn {
  padding: 12px 30px;
  display: inline-block;
  text-align: center;
  background-color: #d1af77;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border-radius: 30px;
  text-decoration: none;
  transition: 0.2s ease;
  cursor: pointer;
  white-space: nowrap;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchConfirmSection
  .FlightSearchConfirmSectionRow1
  .confirmSecBtn:hover {
  background-color: #7a5821;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchConfirmSection
  .FlightSearchConfirmSectionRow2 {
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 10px 0;
  border-top: 1px #bddce9 solid;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchConfirmSection
  .FlightSearchConfirmSectionRow2
  .FlightSearchConfirmSectionRow2DtlIco {
  width: 26px;
  height: 26px;
  float: left;
  min-width: none;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchConfirmSection
  .FlightSearchConfirmSectionRow2
  .FlightSearchConfirmSectionRow2DtlIco
  img {
  width: 100%;
}
.FlightSearchResultBoxSec
  .FlightSearchResultBox
  .FlightSearchResultBoxFirstDtl:last-child {
  border: 0;
}

.SearchFlightMobFilterBtn {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  background-color: #d1af77;
  color: #fff;
  text-align: center;
  font-size: 22px;
  line-height: 60px;
  border-radius: 50px;
  /* display: none; */
  z-index: 99999;
}

.filterSelctBoxRow {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.filterSelctBoxRow label {
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.filterSelctBoxRow input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.filterSelctBoxRow .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 30px;
}

/* On mouse-over, add a grey background color */
.filterSelctBoxRow:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.filterSelctBoxRow input:checked ~ .checkmark {
  background-color: #d1af77;
}

/* Create the checkmark/indicator (hidden when not checked) */
.filterSelctBoxRow .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.filterSelctBoxRow input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.filterSelctBoxRow .checkmark:after {
  left: 10px;
  top: 6px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.return_flight_ico {
  transform: rotateY(11deg);
}
.return_flight_ico img {
  transform: rotate(60deg);
}

.flightArrvlDprtMainBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 3 1;
}

.search_result_showInmob {
  width: 100%;
  height: auto;
  float: left;
  padding: 20px 0;
  padding-right: 50px;
  position: relative;
  display: none;
}
.search_result_showInmob .search_result_showInmob_fromTo {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 16px;
  color: #000;
}
.search_result_showInmob .search_result_showInmob_fromTo img {
  width: 20px;
  position: relative;
  top: -2px;
}
.search_result_showInmob .search_reslultDate_ShowMob {
  width: 100%;
  height: auto;
  display: inline-block;
  font-size: 14px;
  color: #666;
}
.search_result_showInmob .search_result_showInmobBtn {
  width: 40px;
  height: 40px;
  background-color: #00b6ea;
  color: white;
  position: absolute;
  right: 5px;
  top: 26%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.mobSortbySection {
  width: 100%;
  height: auto;
  float: left;
  padding: 10px 0;
  border-top: 1px #e5e5e5 solid;
  border-bottom: 1px #e5e5e5 solid;
  white-space: nowrap;
  overflow: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  display: none;
}
.mobSortbySection::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.mobSortbySection::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.mobSortbySection .MobFltrselect {
  width: auto;
  height: 46px;
  padding: 10px 20px;
  border: solid 1px #e5e5e5;
  border-radius: 5px;
  padding-left: 5px;
  display: inline-block;
}
.mobSortbySection .filterBoxInMobile {
  width: auto;
  padding: 10px 20px;
  border: solid 1px #e5e5e5;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
}

.popMobOverlay {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 9999;
  display: none;
}

.filterCloseBtnMob {
  width: 100%;
  margin-top: 20px;
  padding: 12px 30px;
  display: inline-block;
  text-align: center;
  background-color: #d1af77;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border-radius: 30px;
  text-decoration: none;
  transition: 0.2s ease;
  white-space: nowrap;
  text-decoration: none;
  display: none;
  margin-bottom: 20px;
}
.filterCloseBtnMob:hover {
  background-color: #002d3a;
}

.filterCloseBtn {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
  z-index: 9;
  display: none;
}
.filterCloseBtn .fa {
  font-size: 22px;
}

.FlightSearchResultBoxFirstDtlRow2Mob {
  width: 100%;
  display: none;
  gap: 10px;
  align-items: center;
  padding: 0 10px;
  position: relative;
}
.FlightSearchResultBoxFirstDtlRow2Mob .FlightSearchRightSelectInpt {
  top: 50%;
  position: absolute;
  right: 10px;
  z-index: 9;
}
.FlightSearchResultBoxFirstDtlRow2Mob .FlightSearchRightSelectInpt input {
  width: 22px;
  height: 22px;
}
.FlightSearchResultBoxFirstDtlRow2Mob
  .FlightSearchResultBoxFirstDtlRow2MobFlight {
  display: inline-block;
  max-width: 150px;
}
.FlightSearchResultBoxFirstDtlRow2Mob
  .FlightSearchResultBoxFirstDtlRow2MobTxtDtl {
  width: 100%;
  display: inline-block;
}
.FlightSearchResultBoxFirstDtlRow2Mob
  .FlightSearchResultBoxFirstDtlRow2MobTxtDtl
  .FlightSearchResultBoxFirstDtlRow2MobTime {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 17px;
  color: #242424;
}
.FlightSearchResultBoxFirstDtlRow2Mob
  .FlightSearchResultBoxFirstDtlRow2MobTxtDtl
  .FlightSearchResultBoxFirstDtlRow2MobLoc {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 13px;
  color: #666;
}

.FlightSearchResultBoxFirstDtlRow2MobBtn {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
  display: none;
}
.FlightSearchResultBoxFirstDtlRow2MobBtn span {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5e5e5;
  font-size: 12px;
  color: 242424;
  border-radius: 30px;
  gap: 10px;
}
.FlightSearchResultBoxFirstDtlRow2MobBtn .FlightSearchDtailBtn {
  width: 100%;
  height: 40px;
  text-align: center;
  background: #dcf7ff;
  border-radius: 30px;
  display: inline-block;
  color: #006380;
  font-size: 14px;
  line-height: 40px;
  text-decoration: none;
  transition: 0.2s ease;
  cursor: pointer;
}

.FlightSearchRightFlight {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 0.8 1;
}
.FlightSearchRightFlight .FlightSearchRightFlightImg {
  width: 50px;
  height: 50px;
  overflow: hidden;
  display: grid;
  align-items: center;
}
.FlightSearchRightFlight .FlightSearchRightFlightImg img {
  max-width: 80px;
  max-height: 80px;
  width: 100%;
}
.FlightSearchRightFlight span {
  width: 100%;
  height: auto;
  display: flex;
  gap: 10px;
  font-size: 14px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #868686;
  justify-content: center;
}

.innerPageContantArea {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 50px 0;
  padding-bottom: 20px;
  min-height: auto;
  padding-top: 100px;
  /* overflow: hidden; */
  /* /background: radial-gradient(#d0d0d0, transparent); */
}

/********* Login Page *********/
.loginBoxRow {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.loginBoxRow .loginBoxContainer {
  width: 100%;
  max-width: 1000px;
  height: 500px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 45px #e5e5e5;
}
.loginBoxRow .loginBoxContainer .loginBoxLeftFomSec {
  width: 50%;
  height: 100%;
  text-align: left;
  padding: 60px 25px;
  padding-top: 25px;
  background-color: #fff;
  display: flex;
  position: relative;
}
.loginBoxRow .loginBoxContainer .loginBoxLeftFomSec::before {
  content: "";
  background-image: url(./assets/images/flight-vector-ico.png);
  width: 240px;
  height: 190px;
  left: -110px;
  top: 20px;
  background-size: cover;
  z-index: -1;
  position: absolute;
}
.loginBoxRow .loginBoxContainer .loginBoxLeftFomSec h4 {
  width: 100%;
  color: #242424;
  font-size: 25px;
  display: inline-block;
}
.loginBoxRow .loginBoxContainer .loginBoxLeftFomSec p {
  width: 100%;
  height: auto;
  color: #666;
  font-size: 15px;
  display: inline-block;
  margin-bottom: 15px;
  margin-top: 10px;
}
.loginBoxRow .loginBoxContainer .loginBoxLeftFomSec p a {
  color: #a97d0a;
  text-decoration: none;
}
.loginBoxRow .loginBoxContainer .loginBoxLeftFomSec .form-group-row {
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 20px;
}
.loginBoxRow .loginBoxContainer .loginBoxLeftFomSec .LoginPageSubmitBtn {
  width: 150px;
  height: 50px;
  line-height: 38px;
  border-radius: 30px;
  float: left;
  background-color: #a97d0a;
  text-align: center;
  color: #fff;
  font-size: 15px;
}
.loginBoxRow .loginBoxContainer .loginBoxLeftFomSec .LoginPageSubmitBtn:hover {
  background-color: #664a01;
}
.loginBoxRow .loginBoxContainer .loginBoxLeftFomSec .forgotPass {
  width: 100%;
  height: auto;
  float: left;
  margin-top: 10px;
  color: #242424;
  text-decoration: none;
}
.loginBoxRow .loginBoxContainer .LoginFrmRightSec {
  width: 50%;
  height: 100%;
  background-color: #078ea6;
  display: flex;
  align-items: center;
  position: relative;
  padding: 30px;
}
.loginBoxRow .loginBoxContainer .LoginFrmRightSec::before {
  content: "";
  background-image: url(./assets/images/flight-vector-ico.png);
  width: 240px;
  height: 190px;
  right: -110px;
  top: 20px;
  background-size: cover;
  z-index: -1;
  position: absolute;
  transform: rotate(215deg);
}
.loginBoxRow .loginBoxContainer .LoginFrmRightSec h5 {
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 25px;
}
.loginBoxRow .loginBoxContainer .LoginFrmRightSec h5 span {
  width: 100%;
  height: auto;
  display: inline-block;
  font-size: 15px;
  color: #fff;
}
.loginBoxRow .loginBoxContainer .LoginFrmRightSec img {
  width: 100%;
}

/*******Help Center Style*****/
.HelpCenterFormBoxSec {
  width: 100%;
  height: auto;
  display: inline-block;
  text-align: center;
}
.HelpCenterFormBoxSec .HelpCenterFormBox {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-top: 30px;
  max-width: 800px;
  border: solid 1px #e5e5e5;
  padding: 30px;
  background-color: #fff;
  position: relative;
}
.HelpCenterFormBoxSec .HelpCenterFormBox::before {
  content: "";
  background-image: url(./assets/images/flight-image2.jpg);
  width: 300px;
  height: 210px;
  left: -200px;
  top: 20%;
  background-size: cover;
  z-index: -1;
  position: absolute;
  opacity: 0.2;
  transform: rotate(-115deg);
}
.HelpCenterFormBoxSec .HelpCenterFormBox p {
  width: 100%;
  display: inline-block;
  font-size: 13px;
  color: #666;
  text-align: left;
}
.HelpCenterFormBoxSec .HelpCenterFormBox label {
  text-align: left;
  float: left;
  font-weight: bold;
}
.HelpCenterFormBoxSec .HelpCenterFormBox .flegHelpSelect {
  width: 65px;
  height: 100%;
}
.HelpCenterFormBoxSec .HelpCenterFormBox .flegHelpSelect .dropdown-toggle {
  background-color: #e5e5e5;
  border: 0 !important;
  height: 38px;
  border-radius: 5px 0px 0 5px;
  display: flex;
  align-items: center;
}
.HelpCenterFormBoxSec .HelpCenterFormBox .addAnotherDateofbirthBtn {
  width: auto;
  padding: 8px 20px;
  background-color: #e5e5e5;
  color: #242424;
  float: right;
}
.HelpCenterFormBoxSec .HelpCenterFormBox .addAnotherDateofbirthBtn:hover {
  background-color: #00b6ea;
}
.HelpCenterFormBoxSec .HelpCenterFormBox .help-btn {
  padding: 10px 30px;
  max-width: 150px;
  white-space: nowrap;
}

.termsCheck input {
  width: 17px;
  height: 17px;
}
.termsCheck a {
  color: #a97d0a;
  text-decoration: none;
}

.createAccount {
  overflow: hidden;
  position: relative;
}
.createAccount::before {
  content: "";
  background-image: url(./assets/images/flight-vector-ico.png);
  width: 240px;
  height: 190px;
  right: -130px;
  bottom: 40%;
  background-size: cover;
  z-index: -1;
  position: absolute;
}
.createAccount .loginBoxContainer {
  max-width: 600px;
  padding: 30px;
  height: auto;
  background-color: #fff;
}
@media (max-width: 768px) {
  .searchPageitem.item{margin: auto !important;}
  .searchViewCntSection .col-xl-9.col-lg-8{padding: 0 !important;}
  .passengerFlightDtl .passengerDtlBox{padding: 10px;}
  .accountProfileviewSec{margin: 0 !important;}
  .topCitiesSec .topCitiesCntBox .topCitiesCntBoximg img{width: 100%;}
  .createAccount .loginBoxContainer {
    padding: 10px;
  }
}
.createAccount .loginBoxContainer .loginBoxLeftFomSec {
  width: 100%;
}
.createAccount .loginBoxContainer .loginBoxLeftFomSec::before {
  left: -160px;
}
.createAccount .loginBoxContainer .loginBoxLeftFomSec::after {
  content: "";
  background-image: url(./assets/images/flight-image2.jpg);
  width: 240px;
  height: 190px;
  right: -110px;
  top: 50%;
  background-size: cover;
  z-index: -1;
  position: absolute;
  opacity: 0.2;
}

.accountDtlBanner h4 {
  color: #fff;
  margin-bottom: 20px;
}
.red-text {
  color: red;
  font-size: 30;
}
.fliGhtWithoutAlertSec {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.fliGhtWithoutAlertSec .fliGhtWithoutAlert {
  width: 100%;
  height: 110px;
  max-width: 700px;
  padding: 25px;
  background-color: #fff;
  color: #242424;
  font-size: 18px;
  margin: auto;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.fliGhtWithoutAlertSec .fliGhtWithoutAlert .fliGhtWithoutAlertClose {
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
  color: #242424;
  font-size: 20px;
}

.FilterMobApplyBtns {
  width: 100%;
  height: auto;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  display: none;
}
.FilterMobApplyBtns .FilterMobApplyBtn1 {
  width: 100%;
  height: auto;
  padding: 10px 20px;
  background-color: #fff;
  border: solid 1px #006380;
  color: #242424;
  font-size: 15px;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
}
.FilterMobApplyBtns .FilterMobApplyBtn1:hover {
  background-color: #002d3a;
  color: #fff;
}
.FilterMobApplyBtns .FilterMobApplyBtn2 {
  width: 100%;
  height: auto;
  padding: 10px 20px;
  background-color: #006380;
  border: solid 1px #006380;
  color: #fff;
  font-size: 15px;
  text-align: center;
  border-radius: 10px;
  text-decoration: none;
}
.FilterMobApplyBtns .FilterMobApplyBtn2:hover {
  background-color: #002d3a;
  color: #fff;
}

.ProfileUserDtl {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 40px 0;
  background-color: white;
}
.ProfileUserDtl .ProfileLftPrfBox {
  width: 100%;
  height: auto;
  display: inline-block;
}
.ProfileUserDtl .ProfileLftPrfBox .ProfileLftImageSec {
  width: 100%;
  float: left;
  margin-top: -80px;
  position: relative;
  z-index: 9;
}
.ProfileUserDtl .ProfileLftPrfBox .ProfileLftImageSec .ProfileLftImage {
  width: 100px;
  height: 100px;
  background-color: #f3f3f3;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}
.ProfileUserDtl .ProfileLftPrfBox .ProfileLftImageSec .ProfileLeftName {
  width: 100%;
  float: left;
  font-size: 20px;
  color: #242424;
  font-weight: 600;
}
.ProfileUserDtl .ProfileLftPrfBox .ProfileLftImageSec .ProfileLeftEmail {
  width: 100%;
  float: left;
  font-size: 15px;
  color: #666;
}
.ProfileUserDtl .ProfileLftPrfBox .ProfileLftmenuSec {
  width: 100%;
  float: left;
  margin-top: 50px;
}
.ProfileUserDtl .ProfileLftPrfBox .ProfileLftmenuSec ul {
  padding: 0;
}
.ProfileUserDtl .ProfileLftPrfBox .ProfileLftmenuSec ul li {
  width: 100%;
  height: auto;
  float: left;
  font-size: 14px;
  list-style: none;
  margin-bottom: 20px;
}
.ProfileUserDtl .ProfileLftPrfBox .ProfileLftmenuSec ul li a {
  color: #242424;
  font-size: 19px;
  text-decoration: none;
  width: 100%;
  display: inline-block;
  position: relative;
}
.ProfileUserDtl .ProfileLftPrfBox .ProfileLftmenuSec ul li a:hover {
  color: #956513;
}
.ProfileUserDtl .ProfileLftPrfBox .ProfileLftmenuSec ul li .active {
  color: #956513;
}
.ProfileUserDtl .ProfileLftPrfBox .ProfileLftmenuSec ul li .active::before {
  position: absolute;
  left: 0;
  width: 50px;
  height: 3px;
  background-color: #956513;
  content: "";
  bottom: -2px;
}
.ProfileUserDtl .accountLogedUserRightSec {
  width: 100%;
  height: auto;
  float: left;
}
.ProfileUserDtl .accountLogedUserRightSec .accountDtlHead {
  width: 100%;
  height: auto;
  float: left;
  font-size: 22px;
  color: #242424;
  margin-bottom: 20px;
  position: relative;
}
.ProfileUserDtl .accountLogedUserRightSec .accountLogedUserRightCnt {
  width: 100%;
  height: auto;
  float: left;
  margin-top: 15px;
}
.ProfileUserDtl
  .accountLogedUserRightSec
  .accountLogedUserRightCnt
  .accountDtlQuickDtlBox {
  width: 100%;
  height: 90%;
  float: left;
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 20px 10px;
  text-align: center;
}
.ProfileUserDtl
  .accountLogedUserRightSec
  .accountLogedUserRightCnt
  .accountDtlQuickDtlBox
  span {
  width: 100%;
  float: left;
  font-size: 15px;
  color: #666;
  margin-bottom: 8px;
}
.ProfileUserDtl
  .accountLogedUserRightSec
  .accountLogedUserRightCnt
  .accountDtlQuickDtlBox
  strong {
  width: 100%;
  float: left;
  font-size: 25px;
  color: #242424;
}
.ProfileUserDtl .accountLogedUserRightSec .accountDtlHead2 {
  width: 100%;
  height: auto;
  float: left;
  font-size: 20px;
  color: #242424;
  margin-top: 0px;
}
.ProfileUserDtl .accountLogedUserRightSec .redeemddTabl {
  width: 100%;
  height: auto;
  float: left;
  border: solid 1px #e5e5e5;
  background-color: #fff;
}
.ProfileUserDtl .accountLogedUserRightSec .redeemddTabl td,
.ProfileUserDtl .accountLogedUserRightSec .redeemddTabl th {
  padding: 10px;
  border-bottom: solid 1px #e5e5e5;
  text-align: center;
  border-width: 1px;
}
.ProfileUserDtl .accountLogedUserRightSec .redeemddTabl th {
  font-weight: 600;
  color: #000;
}
.ProfileUserDtl .accountLogedUserRightSec .redeemddTabl td {
  font-weight: 500;
  color: #242424;
  font-family: "poppinsmedium";
}
.ProfileUserDtl .BookingsCntRow {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-bottom: 30px;
}
.ProfileUserDtl .BookingsCntRow .BookingsCntBookdDate {
  width: 100%;
  margin-bottom: 10px;
  float: left;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
}
.ProfileUserDtl .BookingsCntRow .BookingsCntDprtArvl {
  width: 100%;
  margin-bottom: 10px;
  float: left;
  font-size: 18px;
}
.ProfileUserDtl .BookingsCntRow .BookingsCntBox {
  width: 100%;
  height: auto;
  float: left;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 26px rgba(17, 34, 17, 0.1);
  border-radius: 16px;
  padding-bottom: 0;
  margin-bottom: 10px;
}
.ProfileUserDtl .BookingsCntRow .BookingsCntBox .BookingsCntBoxRow {
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px #e5e5e5 solid;
}
.ProfileUserDtl .BookingsCntRow .BookingsCntBox .BookingsCntBoxRow:last-child {
  border: 0;
}
.ProfileUserDtl
  .BookingsCntRow
  .BookingsCntBox
  .BookingsCntBoxRow
  .BookingsCntBoxFlightDtl {
  width: 100%;
  height: auto;
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}
.ProfileUserDtl
  .BookingsCntRow
  .BookingsCntBox
  .BookingsCntBoxRow
  .BookingsCntBoxFlightDtl
  .BookingsCntBoxFlightImg {
  max-width: 100px;
}
.ProfileUserDtl
  .BookingsCntRow
  .BookingsCntBox
  .BookingsCntBoxRow
  .BookingsCntBoxFlightDtl
  .BookingsCntBoxFlightImg
  img {
  width: 100%;
}
.ProfileUserDtl
  .BookingsCntRow
  .BookingsCntBox
  .BookingsCntBoxRow
  .BookingsCntBoxFlightDtl
  .BookingsCntBoxFlightTimeLoc {
  width: 100%;
  height: auto;
  display: flex;
  font-size: 15px;
  color: #242424;
  align-items: center;
  gap: 10px;
}
.ProfileUserDtl
  .BookingsCntRow
  .BookingsCntBox
  .BookingsCntBoxRow
  .BookingsCntBoxFlightDtl
  .BookingsCntBoxFlightTimeLoc
  span {
  width: 100%;
  color: #666;
  font-size: 14px;
  float: left;
}
.ProfileUserDtl
  .BookingsCntRow
  .BookingsCntBox
  .BookingsCntBoxRow
  .BookingsCntBoxFlightDtl
  .BookingsCntBoxFlightTimeLoc
  .FlightHstLine {
  width: 30px;
  height: 2px;
  float: left;
  content: "";
  background-color: #242424;
}
.ProfileUserDtl
  .BookingsCntRow
  .BookingsCntBox
  .BookingsCntBoxRow
  .BookingsCntBoxFlightDtl
  .BookingsCntBoxFlightTimeLoc
  div:last-child {
  text-align: right;
}
.ProfileUserDtl
  .BookingsCntRow
  .BookingsCntBox
  .BookingsCntBoxRow
  .BookingsCntBoxFlightDateDtl {
  width: 50%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.ProfileUserDtl
  .BookingsCntRow
  .BookingsCntBox
  .BookingsCntBoxRow
  .BookingsCntBoxFlightDateDtl
  .BookingsCntBoxFlightDateDtlRow {
  width: 50%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.ProfileUserDtl
  .BookingsCntRow
  .BookingsCntBox
  .BookingsCntBoxRow
  .BookingsCntBoxFlightDateDtl
  .BookingsCntBoxFlightDateIco {
  text-align: center;
  width: 40px;
  height: 35px;
  float: left;
  background: #ecfbff;
  border-radius: 4px;
  padding: 2px;
  margin-right: 9px;
}
.ProfileUserDtl
  .BookingsCntRow
  .BookingsCntBox
  .BookingsCntBoxRow
  .BookingsCntBoxFlightDateDtl
  .BookingsCntBoxFlightDateTxt {
  width: 100%;
  height: auto;
  float: left;
  font-size: 15px;
  color: #242424;
}
.ProfileUserDtl
  .BookingsCntRow
  .BookingsCntBox
  .BookingsCntBoxRow
  .BookingsCntBoxFlightDateDtl
  .BookingsCntBoxFlightDateTxt
  span {
  width: 100%;
  font-size: 13px;
  color: #666;
  float: left;
}
.ProfileUserDtl .BookingsCntRow .BookingsCntBox .flightBookedBtmDtl {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.ProfileUserDtl
  .BookingsCntRow
  .BookingsCntBox
  .flightBookedBtmDtl
  .flightBookedBtmDtlPaidInfo {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.ProfileUserDtl
  .BookingsCntRow
  .BookingsCntBox
  .flightBookedBtmDtl
  .flightBookedBtmDtlPaidInfo
  .flightBookedBtmDtlPaidInfoBox {
  width: 100%;
  height: auto;
  float: left;
  font-size: 18px;
  color: #242424;
}
.ProfileUserDtl
  .BookingsCntRow
  .BookingsCntBox
  .flightBookedBtmDtl
  .flightBookedBtmDtlPaidInfo
  .flightBookedBtmDtlPaidInfoBox
  span {
  width: 100%;
  height: auto;
  float: left;
  font-size: 13px;
  color: #666;
}
.ProfileUserDtl
  .BookingsCntRow
  .BookingsCntBox
  .flightBookedBtmDtl
  .downloadTicketBtn {
  width: auto;
  height: auto;
  padding: 5px 20px;
  white-space: nowrap;
  float: left;
  background-color: #d1af77;
  color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 40px;
  text-decoration: none;
  border-radius: 50px;
}
.ProfileUserDtl
  .BookingsCntRow
  .BookingsCntBox
  .flightBookedBtmDtl
  .downloadTicketBtn:hover {
  background-color: #002d3a;
}

.borderNone {
  border: none !important;
}

.editProfile {
  position: absolute;
  right: 0;
  width: auto;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 40px;
  border: solid 1px #e5e5e5;
  font-size: 16px;
  color: #242424;
  text-decoration: none;
}
.editProfile:hover {
  background-color: #cdf3ff;
}

.accountProfileviewSec {
  width: 100%;
  height: auto;
  float: left;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(17, 34, 17, 0.05);
  border-radius: 16px;
  margin: 10px;
}
.accountProfileviewSec .passengerDtlBox {
  box-shadow: none !important;
}
.accountProfileviewSec .accountProfileviewSecRow {
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 10px;
}
.accountProfileviewSecRow p{margin: 0;}
.accountProfileviewSec .accountProfileviewSecRow label {
  width: 100%;
  height: auto;
  float: left;
  font-size: 13px;
  color: #666;
}
.accountProfileviewSec .accountProfileviewSecRow input {
  font-size: 15px;
  padding-top: 8px;
}
.accountProfileviewSec .accountProfileviewSecRow .inpDslb {
  /* pointer-events: none; */
}

.faqSection {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 40px 0;
}
.faqSection .faqSectionHead {
  width: 100%;
  height: auto;
  float: left;
  font-size: 30px;
  color: #242424;
  text-align: center;
  position: relative;
}
.faqSection .faqSectionHead::before {
  content: "FAQ";
  position: absolute;
  left: 0;
  top: -80px;
  z-index: -1;
  margin: auto;
  width: 100%;
  text-align: center;
  font-size: 150px;
  color: #e5e5e5;
  opacity: 0.3;
}
.faqSection .faqSectionContant {
  width: 100%;
  height: auto;
  float: left;
  margin-top: 20px;
}
.faqSection .faqSectionContant main {
  max-width: 1140px;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10%;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}
.faqSection .faqSectionContant main .row {
  margin-left: -15px !important;
  margin-right: -15px !important;
}
@media screen and (max-width: 992px) {
  .faqSection .faqSectionContant main .row #section-nav {
    background-color: #242424 !important;
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .faqSection .faqSectionContant main .row #section-nav {
    margin-top: 2rem;
  }
}
.faqSection .faqSectionContant main .row #section-nav #nav-section {
  display: block;
  position: -webkit-sticky;
  position: sticky;
  top: 5%;
}
@media screen and (max-width: 992px) {
  .faqSection .faqSectionContant main .row #section-nav #nav-section {
    display: flex;
    background-color: #242424 !important;
  }
}
.faqSection
  .faqSectionContant
  main
  .row
  #section-nav
  #nav-section
  .navbar-brand {
  margin-left: 0.5rem;
  padding-left: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: #242424 !important;
}
.faqSection
  .faqSectionContant
  main
  .row
  #section-nav
  #nav-section
  .navbar-toggler:after {
  font-family: "Font Awesome 5 Free";
  content: "\f078";
  font-size: 1.2rem;
  font-weight: 600;
  color: #242424;
  background-image: none;
  transform: none;
}
.faqSection
  .faqSectionContant
  main
  .row
  #section-nav
  #nav-section
  .navbar-toggler:not(.collapsed)::after {
  font-family: "Font Awesome 5 Free";
  content: "\f077";
  font-size: 1.2rem;
  font-weight: 600;
  color: #242424;
  background-image: none;
  transform: none;
}
.faqSection
  .faqSectionContant
  main
  .row
  #section-nav
  #nav-section
  .navbar-toggler:focus {
  border: 1px solid #242424;
  box-shadow: none;
}
.faqSection
  .faqSectionContant
  main
  .row
  #section-nav
  .navbar-expand-lg
  .navbar-nav
  a {
  color: #242424;
  padding-left: 1rem;
}
.faqSection
  .faqSectionContant
  main
  .row
  #section-nav
  .navbar-expand-lg
  .navbar-nav
  a:hover {
  color: #000;
  text-decoration: underline;
}
.faqSection
  .faqSectionContant
  main
  .row
  #section-nav
  .navbar-expand-lg
  .navbar-nav
  ul {
  margin-bottom: 0;
}
.faqSection
  .faqSectionContant
  main
  .row
  #section-nav
  .navbar-expand-lg
  .navbar-nav
  ul
  li {
  list-style: none;
  padding: 0.5rem 0;
}
.faqSection
  .faqSectionContant
  main
  .row
  #section-nav
  .navbar-expand-lg
  .navbar-nav
  ul
  li
  a {
  text-decoration: none;
  padding-left: 0;
}
.faqSection
  .faqSectionContant
  main
  .row
  #section-nav
  .navbar-expand-lg
  .active {
  border-left: 5px solid #242424;
  padding-bottom: -10px;
  color: #242424 !important;
}
.faqSection .faqSectionContant main .row #two-col p {
  color: #242424;
  font-size: 1.2rem;
  font-weight: 300;
}
.faqSection .faqSectionContant main .row #two-col .section-content {
  margin-bottom: 2rem;
}
.faqSection .faqSectionContant main .row #two-col .section-content a {
  color: #242424;
  text-decoration: none;
  font-weight: 600;
}
.faqSection .faqSectionContant main .row #two-col .section-content a:hover {
  color: #242424;
  text-decoration: underline;
}
.faqSection .faqSectionContant main .row #two-col .section-content h1 {
  color: #242424;
  font-size: 28px;
}
.faqSection .faqSectionContant main .row #two-col .section-content h2 {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 2rem;
  padding: 1rem 0;
  color: #242424;
}
.faqSection
  .faqSectionContant
  main
  .row
  #two-col
  .section-content
  .accordion
  .accordion-item {
  border-bottom: 1px solid #e5e5e5;
}
.faqSection
  .faqSectionContant
  main
  .row
  #two-col
  .section-content
  .accordion
  .accordion-item
  .accordion-header {
  padding: 0;
}
.faqSection
  .faqSectionContant
  main
  .row
  #two-col
  .section-content
  .accordion
  .accordion-item
  .accordion-button {
  font-size: 18px;
  color: #000;
  background: #f3f3f3;
}
.faqSection
  .faqSectionContant
  main
  .row
  #two-col
  .section-content
  .accordion
  .accordion-item
  .accordion-button:focus {
  border: 1px solid #242424;
  box-shadow: none;
}
.faqSection
  .faqSectionContant
  main
  .row
  #two-col
  .section-content
  .accordion
  .accordion-item
  .accordion-button:after {
  font-family: "Font Awesome 5 Free";
  content: "\f078";
  font-size: 1.2rem;
  font-weight: 600;
  color: #e5e5e5;
  background-image: none;
  transform: none;
}
.faqSection
  .faqSectionContant
  main
  .row
  #two-col
  .section-content
  .accordion
  .accordion-item
  .accordion-button:not(.collapsed) {
  background-color: #f3f3f3;
  box-shadow: none;
}
.faqSection
  .faqSectionContant
  main
  .row
  #two-col
  .section-content
  .accordion
  .accordion-item
  .accordion-button:not(.collapsed)::after {
  font-family: "Font Awesome 5 Free";
  content: "\f077";
  font-size: 1.2rem;
  font-weight: 600;
  color: #e5e5e5;
  background-image: none;
  transform: none;
}
.faqSection
  .faqSectionContant
  main
  .row
  #two-col
  .section-content
  .accordion
  .accordion-item
  .accordion-body {
  line-height: 29px;
  color: #242424;
  font-size: 15px;
}

.contactBannr h4 {
  color: #fff;
}

.contactPageSection {
  width: 100%;
  height: auto;
  float: left;
  padding: 40px 0;
  text-align: center;
}
.contactPageSection .contactPageSectionBoxRow {
  width: 100%;
  height: auto;
  display: flex;
  max-width: 800px;
  justify-content: center;
  gap: 20px;
  margin: auto;
}
.contactPageSection .contactPageSectionBoxRow .contactPageSectionBox {
  width: 100%;
  height: auto;
  display: inline-block;
  box-shadow: 0px 0px 20px #f5f5f5;
  padding: 40px 20px;
  background-color: #fff;
}
.contactPageSection .contactPageSectionBoxRow .contactPageSectionBox span {
  width: 100%;
  font-size: 14px;
  color: #666;
}
.contactPageSection .contactPageSectionBoxRow .contactPageSectionBox strong {
  width: 100%;
  display: inline-block;
  font-size: 23px;
}
.contactPageSection .HelpCenterFormBox {
  background-color: #fff;
  box-shadow: 0px 0px 20px #f5f5f5;
  padding: 40px 20px;
  border: 0;
}
.contactPageSection .HelpCenterFormBox h4 {
  text-align: left;
  margin-bottom: 40px;
}

.indicateTxt {
  color: #f00;
  margin-top: 10px;
  margin-bottom: 0;
}

.privacyPolicTermsSec {
  width: 100%;
  height: auto;
  display: inline-block;
}
@media (max-width: 991px) {
  .privacyPolicTermsSec {
    margin-top: 50px;
  }
}
.privacyPolicTermsSec h4 {
  width: 100%;
  height: auto;
  display: inline-block;
  font-size: 25px;
  color: #242424;
  margin-bottom: 10px;
  margin-top: 30px;
  text-transform: uppercase;
}
.privacyPolicTermsSec p {
  width: 100%;
  height: auto;
  display: inline-block;
  font-size: 16px;
  color: #242424;
  margin-bottom: 20px;
  line-height: 28px;
}
.privacyPolicTermsSec strong {
  width: 100%;
  height: auto;
  display: inline-block;
  font-size: 18px;
  color: #242424;
  margin-bottom: 10px;
}

.confirmBookingHead {
  width: 100%;
  height: auto;
  display: inline-block;
  text-align: center;
}
.confirmBookingHead h2 {
  width: 100%;
  height: auto;
  display: inline-block;
  font-size: 30px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 8px;
}
.confirmBookingHead p {
  width: 100%;
  height: auto;
  display: inline-block;
  font-size: 15px;
  color: #fff;
}

.filightDetailsTop {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 40px 0;
  padding-bottom: 0;
}
.filightDetailsTop .FlightDetailsHead {
  width: 100%;
  height: auto;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  color: #242424;
  margin-bottom: 10px;
}
.filightDetailsTop .FlightDetailsBox {
  width: 100%;
  height: auto;
  display: inline-block;
  background-color: #fff;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.filightDetailsTop .FlightDetailsBox .FlightDetailsBoxSubHead {
  width: 100%;
  height: auto;
  float: left;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;
}
.filightDetailsTop .FlightDetailsBox .FlightDetailsBoxRow {
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.filightDetailsTop
  .FlightDetailsBox
  .FlightDetailsBoxRow
  .FlightDetailsFlightdtl {
  width: 300px;
  display: inline-block;
}
.filightDetailsTop
  .FlightDetailsBox
  .FlightDetailsBoxRow
  .FlightDetailsFlightdtl
  img {
  max-width: 70px;
}
.filightDetailsTop
  .FlightDetailsBox
  .FlightDetailsBoxRow
  .FlightDetailsFlightdtl
  h6 {
  width: 100%;
  height: auto;
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.filightDetailsTop
  .FlightDetailsBox
  .FlightDetailsBoxRow
  .FlightDetailsBookddtl {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filightDetailsTop
  .FlightDetailsBox
  .FlightDetailsBoxRow
  .FlightDetailsBookddtl
  .FlightDetailsBookddtlBx
  p {
  width: 100%;
  height: auto;
  float: left;
  font-size: 16px;
  color: #242424;
  margin-bottom: 0;
}
.filightDetailsTop
  .FlightDetailsBox
  .FlightDetailsBoxRow
  .FlightDetailsBookddtl
  .FlightDetailsBookddtlBx
  p
  strong {
  color: #000;
}

.FlightDetailsBookddtl:last-child .FlightDetailsBookddtlBx:last-child {
  text-align: right;
}
.FlightDetailsBookddtlBx{width: 50%;}

.helppageView {
  overflow: hidden;
  position: relative;
}
.helppageView::after {
  content: "";
  background-image: url(./assets/images/illustration-flight.svg);
  width: 100%;
  height: 400px;
  right: -180px;
  top: 10px;
  background-size: cover;
  z-index: -1;
  position: absolute;
  opacity: 0.2;
}

.update_passwordBtn {
  width: auto;
  display: inline-block;
  padding: 7px 20px;
  text-decoration: none;
  background-color: #fff;
  border: solid 1px #e5e5e5;
  color: #242424;
  border-radius: 5px;
  font-size: 14px;
  background-color: #fff0d6;
  padding-top: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.signinConfirmSection {
  width: 100%;
  height: auto;
  float: left;
  padding: 20px 0;
}
.signinConfirmSection .signinConfirmBox {
  width: 100%;
  height: auto;
  float: left;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.signinConfirmSection .signinConfirmBox .signInboxLeft {
  width: 100%;
  height: auto;
  float: left;
  padding: 15px;
  border: solid 1px #e5e5e5;
}
@media (max-width: 768px) {
  .signinConfirmSection .signinConfirmBox .signInboxLeft {
    margin-bottom: 20px;
  }
}
.signinConfirmSection .signinConfirmBox .signInboxLeft h4 {
  width: 100%;
  margin-bottom: 20px;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
}
.signinConfirmSection .signinConfirmBox .signInboxLeft .signInboxLeftBox {
  width: 100%;
  height: auto;
  float: left;
}
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .styled-input {
  float: left;
  width: 100%;
  margin: 10px 0 1rem;
  position: relative;
  background-color: #f5f5f5;
}
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .styled-input
  label {
  color: #999;
  padding: 0;
  position: absolute;
  top: 10px;
  left: 10px;
  transition: all 0.25s cubic-bezier(0.2, 0, 0.03, 1);
  pointer-events: none;
}
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .styled-input.wide {
  width: 100%;
}
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .styled-input
  input,
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .styled-input
  textarea {
  padding: 10px;
  border: 0;
  width: 100%;
  font-size: 1rem;
  background-color: transparent;
}
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .styled-input
  input
  ~ span,
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .styled-input
  textarea
  ~ span {
  display: block;
  width: 0;
  height: 3px;
  background: #a97d0a;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.125s cubic-bezier(0.2, 0, 0.03, 1);
}
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .styled-input
  input:focus,
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .styled-input
  textarea:focus {
  outline: 0;
}
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .styled-input
  input:focus
  ~ label,
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .styled-input
  textarea:focus
  ~ label {
  font-size: 0.75em;
  color: #a97d0a;
  left: 10px;
  top: -18.25px;
  transition: all 0.125s cubic-bezier(0.2, 0, 0.03, 1);
}
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .styled-input
  input:focus
  ~ span,
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .styled-input
  textarea:focus
  ~ span {
  width: 100%;
  transition: all 0.125s cubic-bezier(0.2, 0, 0.03, 1);
}
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .styled-input
  input:valid
  ~ label,
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .styled-input
  textarea:valid
  ~ label {
  font-size: 0.75em;
  color: #a97d0a;
  top: -18.25px;
  left: 10px;
  transition: all 0.125s cubic-bezier(0.2, 0, 0.03, 1);
}
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .forgotPassSignBtnRow {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .forgotPassSignBtnRow
  .forgotBtn {
  color: #000;
  text-decoration: none;
}
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .forgotPassSignBtnRow
  .forgotBtn:hover {
  color: #a97d0a;
}
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .forgotPassSignBtnRow
  .loginBtn {
  width: auto;
  padding: 10px 30px;
  background-color: #a97d0a;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  border-radius: 30px;
}
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .forgotPassSignBtnRow
  .loginBtn:hover {
  background-color: #483300;
}
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .createAccountLogBx {
  width: 100%;
  display: inline-block;
  margin-top: 15px;
  color: #5f5f5f;
  font-size: 14px;
  text-align: center;
}
.signinConfirmSection
  .signinConfirmBox
  .signInboxLeft
  .signInboxLeftBox
  .createAccountLogBx
  a {
  color: #956513;
  font-size: 16px;
  text-decoration: none;
}
.signinConfirmSection .signinConfirmBox .signInboxRight {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.signinConfirmSection .signinConfirmBox .signInboxRight h2 {
  width: 100%;
  display: inline-block;
  color: #a97d0a;
  font-size: 25px;
}
.signinConfirmSection .signinConfirmBox .signInboxRight ul li {
  margin-bottom: 10px;
}

.passengerFlightDtl {
  width: 100%;
  display: inline-block;
  padding: 40px 0;
  padding-top: 0;
}
.passengerFlightDtl .passengerDtlBox {
  width: 100%;
  height: auto;
  float: left;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-bottom: 20px;
}
.passengerFlightDtl .passengerDtlBox .passengerDtlBoxHead {
  width: 100%;
  height: auto;
  float: left;
  color: #112211;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.passengerFlightDtl .passengerDtlBox .passengerDtlNote {
  width: 100%;
  height: auto;
  float: left;
  color: #9f760b;
  font-size: 14px;
}
.passengerFlightDtl .passengerDtlBox .passengerDtlNote strong {
  color: #000;
}
.passengerFlightDtl .passengerDtlBox .passengerDtlNote p {
  color: #666;
}
.passengerFlightDtl .passengerDtlBox .passengerDtlFrom {
  width: 100%;
  height: auto;
  float: left;
  margin-top: 15px;
}
.passengerFlightDtl .passengerDtlBox .passengerDtlFrom label {
  width: 100%;
  height: auto;
  float: left;
  font-size: 15px;
  color: #000;
}
.passengerFlightDtl .passengerDtlBox .passengerDtlFrom label span {
  color: #9f760b;
}
.passengerFlightDtl .passengerDtlBox .passengerDtlFrom .BookingFormInput {
  width: 100%;
  height: 40px;
  float: left;
  font-size: 15px;
  color: #000;
  padding-left: 8px;
  border-radius: 5px;
  border: solid 1px #e5e5e5;
  outline: none;
}


.passengerFlightDtl .passengerDtlBox .psportDtl {
  width: 100%;
  height: auto;
  float: left;
  color: #004f85;
  font-size: 14px;
  margin-top: 10px;
  text-decoration: none;
  text-align: right;
}
.passengerFlightDtl .FlightBookDtlAccrd {
  background-color: transparent;
  margin-top: 20px;
}
.passengerFlightDtl .FlightBookDtlAccrd .accordion-item {
  border: 0;
}
.passengerFlightDtl .FlightBookDtlAccrd .accordion-button {
  background-color: transparent;
  color: #242424;
}
.passengerFlightDtl .CancelationSubTxt {
  width: 100%;
  display: inline-block;
  padding: 10px;
  color: #9f760b;
  font-size: 15px;
  background-color: #f1f8ff;
}
.passengerFlightDtl .protectionSelctBox {
  width: 100%;
  height: auto;
  float: left;
  margin-top: 10px;
  border: solid 1px #ccc;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.passengerFlightDtl .protectionSelctBox:hover {
  border: solid 1px #a97d0a;
}
.passengerFlightDtl .protectionSelctBox .protectionSelctBoxHead {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px #e5e5e5 solid;
  flex-wrap: nowrap;
}
@media (max-width: 600px) {
  .passengerFlightDtl .protectionSelctBox .protectionSelctBoxHead {
    display: inline-block;
  }
}
.passengerFlightDtl
  .protectionSelctBox
  .protectionSelctBoxHead
  .protectionSelctBoxlftsc {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.passengerFlightDtl
  .protectionSelctBox
  .protectionSelctBoxHead
  .protectionSelctBoxlftsc
  input {
  width: 25px;
  height: 25px;
}
.passengerFlightDtl .protectionSelctBox .protectionSelctBoxHead strong {
  color: #00b67a;
  white-space: nowrap;
}
.passengerFlightDtl .protectionSelctBox .protectionSelctBoxCnt {
  width: 100%;
  height: auto;
  float: left;
}
.passengerFlightDtl
  .protectionSelctBox
  .protectionSelctBoxCnt
  .cancelPrtctList {
  width: 100%;
  padding: 0;
  margin-top: 10px;
}
.passengerFlightDtl
  .protectionSelctBox
  .protectionSelctBoxCnt
  .cancelPrtctList
  li {
  list-style: none;
  padding-left: 40px;
  margin-bottom: 10px;
  position: relative;
  color: #434343;
  font-size: 14px;
}
.passengerFlightDtl
  .protectionSelctBox
  .protectionSelctBoxCnt
  .cancelPrtctList
  li::after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  left: 10px;
  top: 3px;
  background-image: url(./assets/images/tick.svg);
  background-repeat: no-repeat;
}
.passengerFlightDtl .protectionSelctBox .cantAddllaterBtn {
  width: auto;
  padding: 5px 15px;
  background-color: #002e3d;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 4px;
}
@media (max-width: 768px) {
  .passengerFlightDtl .protectionSelctBox .cantAddllaterBtn {
    margin-top: 10px;
    display: inline-block;
  }
}
.passengerFlightDtl .border-none {
  border: 0 !important;
}
.passengerFlightDtl .active {
  border: solid 1px #13c8ff;
}

.recomemendedBox {
  padding: 5px;
  background-color: #a97d0a;
  color: #fff;
  font-size: 14px;
  display: inline-block;
  border-radius: 5px;
  font-weight: normal;
  position: relative;
  top: -2px;
  margin-left: 8px;
}

.seatSelectSec {
  width: 100%;
  height: auto;
  float: left;
}
.seatSelectSec .seatSelectSecBox {
  width: 100%;
  background: #ffffff;
  border: 1px solid #b6b6b6;
  border-radius: 10px;
  display: inline-block;
  padding: 10px;
  cursor: pointer;
}
.seatSelectSec .seatSelectSecBox .seatSelectSecBoxico {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.seatSelectSec .seatSelectSecBox .seatSelectSecTxt {
  width: 100%;
  float: left;
  color: #242424;
  font-size: 17px;
}

.includeBagabeCnt {
  width: 100%;
  height: auto;
  float: left;
}

.includeBagabeCntRow {
  width: 100%;
  height: auto;
  float: left;
  background: #f1f8ff;
  border-radius: 3px;
  position: relative;
  padding: 8px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.includeBagabeCntRow .bagageIco {
  width: 50px;
  text-align: center;
}

.FlightRightPanel {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
}
.FlightRightPanel .FlightRightPanelBox {
  width: 100%;
  height: auto;
  float: left;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-bottom: 20px;
}
.FlightRightPanel .FlightRightPanelBox .FlightRightPanelHead {
  width: 100%;
  height: auto;
  float: left;
  color: #112211;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.FlightRightPanel .FlightRightPanelBox .FlightRightPanelCnt {
  width: 100%;
  height: auto;
  float: left;
}
.FlightRightPanel
  .FlightRightPanelBox
  .FlightRightPanelCnt
  .FlightRightPanelCntHead {
  width: 100%;
  height: auto;
  float: left;
  color: #9f760b;
  margin-bottom: 5px;
  font-size: 14px;
}
.FlightRightPanel
  .FlightRightPanelBox
  .FlightRightPanelCnt
  .FlightRightPanelCntRow {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 4px;
  color: #000;
  gap: 5px;
}
.FlightRightPanel
  .FlightRightPanelBox
  .FlightRightPanelCnt
  .FlightRightPanelCntRow
  span:last-child {
  text-align: right;
}
.FlightRightPanel
  .FlightRightPanelBox
  .FlightRightPanelCnt
  .FlightRightPanelCntRow
  .fa-info {
  width: 20px;
  height: 20px;
  line-height: 17px;
  font-size: 12px;
  color: #666;
  border-radius: 50%;
  border: solid 1px #666;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.FlightRightPanel
  .FlightRightPanelBox
  .FlightRightPanelCnt
  .FlightRightPanelCntRow
  .fa-info
  .infoPopupDtl {
  width: 300px;
  height: auto;
  position: absolute;
  left: 20px;
  background-color: #fff;
  top: 0;
  z-index: 9;
  box-shadow: 0px 0px 20px #999999;
  font-weight: 200;
  padding: 10px;
  text-align: left;
  border-radius: 5px;
  display: none;
}
@media (max-width: 1400px) {
.flight_more_dtl_box_left_cnt .flightSearchDtlBtmDprtArvl{padding:10px}
.bookingFomrBox .searchBookBtmRow .searchBookBtmLocation .searchBookBtmBox{ max-width: 200px;}
}

@media (max-width: 1300px) {
  .FlightRightPanel
    .FlightRightPanelBox
    .FlightRightPanelCnt
    .FlightRightPanelCntRow
    .fa-info
    .infoPopupDtl {
    right: -150px;
    top: 20px;
    left: inherit;
  }
}
@media (max-width: 768px) {
  .FlightRightPanel
    .FlightRightPanelBox
    .FlightRightPanelCnt
    .FlightRightPanelCntRow
    .fa-info
    .infoPopupDtl {
    right: 0;
    top: 20px;
    left: -100px;
  }
}
.FlightRightPanel
  .FlightRightPanelBox
  .FlightRightPanelCnt
  .FlightRightPanelCntRow
  .fa-info
  .infoPopupDtl
  .infoPopupDtlRow {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #242424;
  margin-bottom: 8px;
  font-family: "poppinsmedium";
}
@media (max-width: 768px) {
  .FlightRightPanel
    .FlightRightPanelBox
    .FlightRightPanelCnt
    .FlightRightPanelCntRow
    .fa-info
    .infoPopupDtl
    .infoPopupDtlRow {
    font-size: 12px;
  }
}
.FlightRightPanel
  .FlightRightPanelBox
  .FlightRightPanelCnt
  .FlightRightPanelCntRow
  .fa-info:hover
  .infoPopupDtl {
  display: block;
}
.FlightRightPanel .FlightRightPanelBox .FlightRightPanelCnt .totalAmnt {
  font-size: 18px;
  margin-top: 10px;
}
.FlightRightPanel .FlightRightPanelBox .FlightRightPanelCnt .totalDisCounts {
  font-size: 12px !important;
  font-weight: 800;
  margin-top: 10px;
  color: #009362;
}
.FlightRightPanel
  .FlightRightPanelBox
  .FlightRightPanelCnt
  .totalAmnt
  strong
  p {
  font-weight: normal;
  font-size: 14px;
}
.FlightRightPanel
  .FlightRightPanelBox
  .FlightRightPanelCnt
  .continuePaymentBtn {
  width: 100%;
  height: auto;
  display: inline-block;
  color: #fff;
  background-color: #a97d0a;
  text-align: center;
  padding: 10px 0;
  margin-top: 10px;
  border-radius: 20px;
  text-decoration: none;
  transition: 0.2s ease;
}
.FlightRightPanel
  .FlightRightPanelBox
  .FlightRightPanelCnt
  .continuePaymentBtn:hover {
  background-color: #002e3d;
}
#payemntFix {
  position: static !important;
  max-width: 100%;
}
#payemntFix.LeftFixScrl {
  position: fixed !important;
  top: 100px;
  height: auto;
  max-width: 400px;
}
.LeftFixNotScrl {
  position: absolute;
  bottom : 0px

}
@media (max-width: 991px) {
  .LeftFixScrl {
    position: static !important;
    max-width: inherit;
  }
}

.paymentCardEnterBox {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 10px;
  border: solid 1px #e5e5e5;
  border-radius: 20px;
}
.paymentCardEnterBox .paymentCardEnterBoxHead {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-bottom: 20px;
}
.paymentCardEnterBox .paymentCardEnterBoxHead .paymentCardBtn {
  width: 200px;
  padding: 5px 10px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #9f760b;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;
}
.paymentCardEnterBox .paymentCardEnterBoxHead .paymentCardBtn span {
  width: 100%;
  height: auto;
  display: inline-block;
}
.paymentCardEnterBox .paymentCardEnterBoxHead .paymentUseSvdCardrow {
  width: 100%;
  height: auto;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
}

.helpCsvCodeIco {
  display: inline-block;
  width: 20px;
  height: 20px;
  color: #fff;
  background-color: #a97d0a;
  border-radius: 40px;
  text-align: center;
  cursor: pointer;
  position: relative;
}
.helpCsvCodeIco .csvCodeBoxInfo {
  width: 250px;
  height: auto;
  position: absolute;
  left: -125px;
  background-color: #fff;
  bottom: 30px;
  z-index: 9;
  box-shadow: 0px 0px 20px #e5e5e5;
  font-weight: 200;
  padding: 10px;
  text-align: left;
  border-radius: 5px;
  display: none;
}
.helpCsvCodeIco .csvCodeBoxInfo:before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 10px 10px 10px;
  border-color: #ffffff transparent transparent;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  margin: auto;
}
.helpCsvCodeIco .csvCodeBoxInfo p {
  width: 100%;
  float: left;
  margin-bottom: 8px;
  font-size: 14px;
  color: #666;
}
.helpCsvCodeIco .csvCodeBoxInfo .cardImage {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
.helpCsvCodeIco .csvCodeBoxInfo .cardImage img {
  width: 100%;
}
.helpCsvCodeIco:hover .csvCodeBoxInfo {
  display: block;
}

.couponCodeSec {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  align-items: center;
}
.couponCodeSec div {
  position: relative;
}
.couponCodeSec div img {
  height: 30px;
}
.couponCodeSec span {
  width: 100%;
  font-size: 13px;
  color: #666;
}
.couponCodeSec span strong {
  width: 100%;
  display: inline-block;
  padding-left: 35px;
  top: -6px;
  color: #000;
}
.couponCodeSec strong {
  position: relative;
  top: 3px;
  font-size: 15px;
  margin-left: 5px;
}
.couponCodeSec .couponentrinput {
  width: 100%;
  height: 32px;
  border: solid 1px #e5e5e5;
  padding-right: 70px;
  padding-left: 5px;
  border-radius: 20px;
}
.couponCodeSec .btnpromoApply {
  background-color: #9f760b;
  color: #fff;
  height: 26px;
  position: absolute;
  top: 3px;
  width: 60px;
  right: 5px;
  text-align: center;
  line-height: 16px;
  text-decoration: none;
  font-size: 13px;
  border-radius: 20px;
}

.bookingpaymentReviewSec {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-top: 20px;
}
.bookingpaymentReviewSec h3 {
  width: 100%;
  display: inline-block;
  color: #000000;
  font-size: 24px;
  text-align: left;
    margin-bottom: 10px;
}
.bookingpaymentReviewSec ol {
  width: 100%;
  display: inline-block;
  text-align: left;
}
.bookingpaymentReviewSec ol li {
  width: 100%;
  font-size: 12px;
  color: #666;
  margin-bottom: 10px;
}
.bookingpaymentReviewSec p {
  width: 100%;
  float: left;
  color: #666;
  font-size: 14px;
  margin-bottom: 20px;
}
.bookingpaymentReviewSec p a {
  color: #000;
  text-decoration: none;
  font-weight: 600;
}
.confirm_page_border_box {
  width: 100%;
  height: auto;
  display: inline-block;
  /* margin-top: 20px; */
  border: 1px solid rgb(226, 226, 232);
  border-radius: 8px;
  padding:15px
}

.paymentBottomBtnRow {
  width: 100%;
  height: auto;
  display: inline-block;
  /* margin-top: 20px; */
  text-align: center;
  border: 1px solid rgb(226, 226, 232);
  border-radius: 8px;
  padding:20px
}
.paymentBottomBtnRow .totalPayment {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-bottom: 8px;
  color: #000;
  font-size: 30px;
  line-height: 10px;
}
.paymentBottomBtnRow .totalPayment p {
  font-weight: 200;
  font-size: 14px;
}
.paymentBottomBtnRow .completeBooking {
  width: 100%;
  max-width: 250px;
  height: auto;
  display: inline-block;
  color: #fff;
  background-color: #9f760b;
  text-align: center;
  padding: 15px 0;
  margin-top: 10px;
  border-radius: 10px;
  text-decoration: none;
  transition: 0.2s ease;
  font-size: 16px;
}

.earnpointBox {
  width: 100%;
  height: 100%;
  min-height: 360px;
  float: left;
  padding: 15px;
  text-align: center;
  border: solid 2px #7a4c01;
  margin-bottom: 20px;
  position: relative;
}
.earnpointBox .earnpointBoxImg {
  width: 100%;
  height: auto;
  float: left;
}
.earnpointBox .earnpointBoxImg img {
  width: 100%;
  max-width: 160px;
}
.earnpointBox .earnpointBoxHead {
  width: 100%;
  height: auto;
  float: left;
  font-size: 22px;
  color: #000;
  font-weight: 600;
}
.earnpointBox .earnpointBoxCnt {
  width: 100%;
  height: auto;
  float: left;
  font-size: 16px;
  color: #242424;
  line-height: 30px;
}

.border0::after {
  display: none;
}

.paymentSummary .FlightRightPanelCntRow strong {
  font-size: 18px;
}

.FlightRightPanelCntRowBox {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.InfopopHead {
  font-size: 18px !important;
  color: #000;
  margin-bottom: 10px !important;
  font-family: "poppinsmedium";
}

.register_btn {
  width: auto;
  padding: 10px 30px;
  background-color: #d1af77;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  border-radius: 30px;
  margin-top: 30px;
  transition: 0.2s ease;
  display: inline-block;
}
.register_btn:hover {
  color: #fff;
  background-color: #002d3a;
}

.footerTopdiv {
  width: 100%;
  height: auto;
  float: left;
}

#payemntFix {
  max-width: 420px;
}

.mobileShow {
  display: none;
}

.accountDetailsQuickBlanceSection {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 20px 0;
}
.accountDetailsQuickBlanceSection .accountDetailsQuickBlanceBox {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px #e5e5e5;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  border: solid 3px #fff;
  cursor: pointer;
  transition: 0.2s ease;
}
.accountDetailsQuickBlanceSection .accountDetailsQuickBlanceBox::before {
  width: 90px;
  height: 90px;
  position: absolute;
  right: -30px;
  top: -20px;
  background-color: #ecfbff;
  content: "";
  border-radius: 50px;
  z-index: -1;
}
.accountDetailsQuickBlanceSection
  .accountDetailsQuickBlanceBox
  .accountDetailsQuickBlanceBoxIco {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.accountDetailsQuickBlanceSection
  .accountDetailsQuickBlanceBox
  .accountDetailsQuickBlanceBoxIco
  span {
  display: flex;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background-color: #d1af77;
  align-items: center;
  justify-content: center;
  padding: 10px;
  /* box-shadow: 0px 10px 20px #aed8e5; */
}
.accountDetailsQuickBlanceSection
  .accountDetailsQuickBlanceBox
  .accountDetailsQuickBlanceBoxIco
  span
  img {
  height: 40px;
}
.accountDetailsQuickBlanceSection
  .accountDetailsQuickBlanceBox
  .accountDetailsQuickBlanceBoxTxt {
  width: 100%;
  height: auto;
  display: inline-block;
  font-size: 17px;
  color: #242424;
}
.accountDetailsQuickBlanceSection
  .accountDetailsQuickBlanceBox
  .accountDetailsQuickBlanceBoxTxt
  strong {
  width: 100%;
  display: inline-block;
  font-size: 17px;
  color: #000;
}
.accountDetailsQuickBlanceSection  .col-md-3:first-child .accountDetailsQuickBlanceBox{box-shadow: none;pointer-events: none;}
.accountDetailsQuickBlanceSection .accountDetailsQuickBlanceBox:hover {
  border: solid 3px #66dbff;
}
.accountDetailsQuickBlanceSection .activeBx {
  border: solid 3px #d1af77;
}

.bgColor span {
  background-color: #d1af77 !important;
}

.bgColor2 span {
  background-color: #d1af77 !important;
}

.bgColor3 span {
  background-color: #d1af77 !important;
}

.btn-primary {
  background-color: #d1af77;
  border-color: #d1af77;
}

.referalContentBox {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 15px;
}
.referalContentBox .referlcodeInput {
  width: 100%;
  height: 42px;
  background-color: white;
  border: solid 1px #e5e5e5;
  position: relative;
  border-radius: 40px;
  overflow: hidden;
  max-width: 500px;
}
.referalContentBox .referlcodeInput .referlcodetxtbox {
  width: 100%;
  height: 40px;
  border: 0;
  padding-left: 10px;
  font-size: 15px;
  padding-right: 90px;
  outline: none;
}
.referalContentBox .referlcodeInput .referlCodeCopyBtn {
  width: 80px;
  height: 40px;
  position: absolute;
  right: 0;
  background-color: #004f85;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  text-decoration: none;
}
.referalContentBox .referlCodeIconSec {
  width: 100%;
  height: auto;
  display: flex;
  gap: 15px;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .referalContentBox .referlCodeIconSec {
    flex-wrap: wrap;
  }
}
.referalContentBox .referlCodeIconSec .referlCodeIcon {
  font-size: 14px;
  color: #242424;
  text-align: center;
  display: inline-block;
}
.referalContentBox .referlCodeIconSec .referlCodeIcon span {
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  color: #fff;
  font-size: 25px;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.referalContentBox .referlCodeIconSec .referlCodeIcon strong {
  margin-top: 5px;
  width: 100%;
  display: inline-block;
}
.referalContentBox .referlCodeIconSec .msg_ico {
  background-color: #40e46a;
}
.referalContentBox .referlCodeIconSec .email_ico {
  background-color: #0179fe;
}
.referalContentBox .referlCodeIconSec .whtsapp_ico {
  background-color: #2fd265;
}
.referalContentBox .referlCodeIconSec .fb_ico {
  background-color: #1478f2;
}
.referalContentBox .referlCodeIconSec .msger_ico {
  background-color: #ac37e9;
}

.earnpointTablFilter {
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 10px;
  gap: 10px;
  align-items: center;
  margin-top: 40px;
  flex-wrap: wrap;
}

.earnpointTablFilterInput {
  display: inline-block;
  /* min-width: 400px; */
}
.earnpointTablFilterInput DateRangePicker {
  width: 100%;
  height: 38px;
  border: solid 1px #b4afaf;
  outline: none;
  padding-left: 8px;
}

.earnpointTabl {
  width: 100%;
  height: auto;
  float: left;
  max-width: 600px;
}

.earnpointTabl th,
td {
  font-size: 15px;
  color: #000;
  border: solid 1px #ccc;
  padding: 8px;
  text-align: center;
}

.pointerDsble {
  pointer-events: none;
}

.flightSearchDtlBtmSection {
  width: 100%;
  height: auto;
  display: flex;
  border: dashed 1px #b7b3b3;
  padding: 20px 60px;
  position: relative;
  border-radius: 5px;
  justify-content: center;
  margin-bottom: 1px;
  justify-content: space-between;
  background-color: rgba(253, 253, 253, 0.5294117647);
}
.flightSearchDtlBtmSection .layouvrBox {
  position: absolute;
  width: 140px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -13px;
  z-index: 2;
  padding: 5px 10px;
  background-color: #dae9f7;
  color: #585858;
  font-size: 12px;
  border-radius: 20px;
  text-align: center;
}
.flightSearchDtlBtmSection .flightSearchDtlBtmImgSec {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 0.8 1;
  text-align: center;
  max-width: 100px;
}
.flightSearchDtlBtmSection .flightSearchDtlBtmImgSec .flightSearchDtlBtmImg {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  overflow: visible;
}
.flightSearchDtlBtmSection
  .flightSearchDtlBtmImgSec
  .flightSearchDtlBtmImg
  img {
  mix-blend-mode: darken;
  height: 50px;
  width: 100%;
}
.flightSearchDtlBtmSection .flightSearchDtlBtmImgSec span {
  width: 100%;
  height: auto;
  float: left;
  font-size: 14px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #868686;
}
.flightSearchDtlBtmSection .flightSearchDtlBtmDprtArvl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 3 1;
  max-width: 500px;
}
.flightSearchDtlBtmSection .flightSearchDtlBtmDprtArvl .FlightBtmDepartureBox {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  flex: 0.75 1;
}
.flightSearchDtlBtmSection
  .flightSearchDtlBtmDprtArvl
  .FlightBtmDepartureBox
  span {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  color: #868686;
  display: inline-block;
}
.flightSearchDtlBtmSection .flightSearchDtlBtmDprtArvl .FlightDtlTtlDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1.5 1;
  text-align: center;
  padding: 0 10px;
}
.flightSearchDtlBtmSection
  .flightSearchDtlBtmDprtArvl
  .FlightDtlTtlDetail
  .FlightDtlTtlHour {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: 13px;
  color: #000;
  position: relative;
}
.flightSearchDtlBtmSection
  .flightSearchDtlBtmDprtArvl
  .FlightDtlTtlDetail
  .FlightDtlTtlHour
  strong {
  background-color: #fff;
  position: relative;
  padding: 0 5px;
  padding-top: 2px;
  font-weight: normal;
}
.flightSearchDtlBtmSection
  .flightSearchDtlBtmDprtArvl
  .FlightDtlTtlDetail
  .FlightDtlTtlHour::before {
  width: 100%;
  height: 1px;
  border-bottom: 1px #ccc dashed;
  position: absolute;
  top: 50%;
  left: 0;
  content: "";
}
.flightSearchDtlBtmSection
  .flightSearchDtlBtmDprtArvl
  .FlightDtlTtlDetail
  .FlightDtlTtlflightDrtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  color: #000;
}

.flightSarchinformationTabBtnSec {
  width: 100%;
  height: auto;
  float: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #dae9f7;
  margin-bottom: 20px;
  border-radius: 20px;
}
.flightSarchinformationTabBtnSec .flightSarchinformationTabBtn {
  color: #242424;
  font-size: 14px;
  cursor: pointer;
}
.flightSarchinformationTabBtnSec .active {
  background-color: #004f85;
  color: #fff;
  border-radius: 20px;
  padding: 6px 10px;
}

.faredtlTable {
  width: 100%;
  float: left;
  background-color: rgba(253, 253, 253, 0.5294117647);
}
.faredtlTable td {
  font-size: 14px;
  text-align: left;
}

.flightBtmDtlFlightInformbox {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.bagageDtl .FlightSearchRightFlight {
  display: flex;
  justify-content: left;
  flex-direction: inherit;
  align-items: center;
  flex: 0.8 1;
  padding: 3px 10px;
  background-color: #e4f0fb;
  margin: 10px 0;
  float: left;
  width: 100%;
}

.profileBtnRow {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  float: left;
}
.profileBtnRow .profileBtn {
  width: auto;
  padding: 8px 22px;
  background-color: #a97d0a;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border: solid 1px #a97d0a;
  text-decoration: none;
  border-radius: 5px;
}
.profileBtnRow .profileBtn:hover {
  background-color: #002e3d;
  color: #fff;
}
.profileBtnRow .profileBtn_1 {
  background-color: #fff;
  border: solid 1px #a97d0a;
  color: #000;
}

.addorSavedAddressSelection {
  width: 100%;
  height: auto;
  float: left;
  margin: 10px 0;
}

.passengerDtlEntry {
  width: 100%;
  display: inline-block;
}

.addnewPassenger {
  width: 100%;
  display: inline-block;
  margin-top: 25px;
  text-decoration: none;
  color: #956513;
}

/* .DtlShow {
  display: block !important;
} */

.mobShow {
  display: none !important;
}

.addnewPassengerBtn {
  width: auto;
  padding: 12px 30px;
  border: solid 1px #e5e5e5;
  font-size: 16px;
  color: #242424;
  text-decoration: none;
  margin-bottom: 10px;
  border-radius: 10px;
  display: inline-block;
}
.addnewPassengerBtn:hover {
  background-color: #002e3d;
  color: #fff;
}

.savedAddressBox {
  width: 100%;
  height: auto;
  float: left;
  margin-top: 10px;
}
.savedAddressBox .savedAddressBoxRow {
  width: 100%;
  height: auto;
  padding: 5px 10px;
  box-shadow: 0px 5px 10px #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  max-width: 600px;
  border-radius: 5px;
}
.savedAddressBox .savedAddressBoxRow .savedAddressBoxName {
  font-size: 17px;
  color: #000;
  font-weight: 600;
}
.savedAddressBox .savedAddressBoxRow .savedAddressBoxBtn {
  width: auto;
  padding: 5px 10px;
  border: solid 1px #e5e5e5;
  font-weight: 500;
  color: #000;
  border-radius: 5px;
  text-decoration: none;
}
.savedAddressBox .savedAddressBoxRow .savedAddressBoxBtn:hover {
  background-color: #b9f0ff;
}

.show {
  display: block !important;
}

.addNewPassengerButton {
  cursor: pointer;
}

.form-control {
  -webkit-appearance: menulist;
  min-height: 40px;
} /*# sourceMappingURL=common.css.map */

.border0::after {
  display: none;
} /*# sourceMappingURL=common.css.map */

.DateRangePickerInput__withBorder {
  border: none !important;
}
.SingleDatePickerInput__withBorder {
  border: none !important;
}
.DateInput__small {
  width: 92px !important;
  /* //width: 7px !important; */
}
.FlightSearchpageRighttopSort {
  border: none !important;
}
.DateInput_input__small {
  padding: 7px 2px 5px !important;
  font-weight: 400 !important;
}

.hasErrors {
  font-size: 12px !important;
  color: rgb(158, 54, 54) !important;
}

/* .hasErrors:after{
  content:'?';
  font-size: 12px;
  color: rgb(158, 54, 54) !important;
} */
.hasRequired:after {
  content: "*";
  font-size: 12px;
  color: rgb(158, 54, 54) !important;
}
.labelHeight {
  position: absolute;
  top: -24px !important;
  left: -8px !important;
  font-size: 12px;
  color: #000 !important;
  cursor: pointer;
}
.searchBookDateBox .DateRangePicker {
  top: -11px;
  left: 10px;
  font-size: 16px;
}
.searchBookDateBox .SingleDatePicker_1 {
  top: -11px;
  left: 10px;
}

.css-1nmdiq5-menu{
  min-width: 400px;
}




/* 
Responsive   starts here=================================================================== */

@media (max-width: 1400px) {
  .bookingFomrBox .searchBookBtmRow .searchBookDateBox {
    width: 23%;
  }
  .ProfileUserDtl
    .BookingsCntRow
    .BookingsCntBox
    .BookingsCntBoxRow
    .BookingsCntBoxFlightDateDtl {
    width: 70%;
  }
}
@media (max-width: 1200px) {
  .DateInput__small {
    width: 80px !important;
  }
  .DateInput_input__small {
    font-size: 16px !important;
    letter-spacing: 0 !important;
  }
  .flight_more_dtl_box_cnt{flex-wrap: wrap;}
  .flight_more_dtl_box_left_sec{width: 100% !important;}
  .flight_more_dtl_box_right_sec{width: 100%  !important;}
  .bookingFomrBox .searchBookBtmRow .searchBookBtmLocation .searchBookBtmBox{ max-width: 160px;}
}
@media (max-width: 1020px) {
  .loginBoxRow .loginBoxContainer {
    height: auto;
    display: inline-block;
    box-shadow: none;
  }
  .loginBoxRow .loginBoxContainer .loginBoxLeftFomSec {
    width: 100%;
  }
  .loginBoxRow .loginBoxContainer .LoginFrmRightSec {
    width: 100%;
    min-height: 390px;
  }
  /* .loginBoxRow{margin: 80px 0;} */
}
@media (max-width: 991px) {
  .css-1nmdiq5-menu{min-width: 100%;  }
  .bookingFomrBox .searchBookBtmRow .searchBookBtmLocation .searchBookBtmBox{max-width: 100%;padding-bottom: 8px;}
  .mobMenuFull {
    width: 100% !important;
    position: absolute;
    right: 0;
    top: 20px;
  }
  .navbar-toggler {
    margin-right: 0%;
  }
  .topMainHead .navbar-expand-lg .navbar-nav .nav-link.manageBookingBtn {
    width: auto;
    max-width: 180px;
    margin-bottom: 11px;
  }
  .topMainBanner img {
    width: auto;
    max-height: 500px;
  }
  .navbar-nav {
    background-color: #fff;
    margin-top: 20px;
  }
  .bookingFomrBox .searchBookBtmRow {
    width: 100%;
    display: inline-block;
    margin-top: 12px;
    position: relative;
  }
  .bookingFomrBox .searchBookBtmRow .searchBookBtmLocation {
    width: 100%;
    margin-bottom: 10px;
  }
  .bookingFomrBox .searchBookBtmRow .searchBookDateBox {
    width: 100%;
    margin-bottom: 10px;
  }
  .bookingFomrBox .searchBookBtmRow .searchBookBoxBtn {
    width: 100%;
  }
  .cntrBoxFtr {
    border: 0 !important;
  }
  .homeFeatureSection .homeFeatureBox {
    width: 100%;
    overflow: hidden;
  }
  .topDestinationScroll {
    margin-top: 20px !important;
  }
  .exploreTopDestination .topDestinationScroll .owl-prev {
    left: 8px;
  }
  .exploreTopDestination .topDestinationScroll .owl-next {
    right: 8px;
  }
  .topMainHead .logoTopSection {
    margin-top: 2px;
    width: auto;
    position: relative;
    z-index: 99;
  }
  .navbar-expand-lg .navbar-nav .nav-link.accountLoginBoxTop {
    margin: auto !important;
    margin-bottom: 10px !important;
  }
  .topMainHead .navbar-expand-lg .navbar-nav li {
    display: inline-block !important;
  }

  .SearchFlightMobFilterBtn {
    display: block;
  }

  .searchViewCntSection .SearchPageLeftFilterSection {
    box-shadow: none;
    padding: 0;
  }
  .search_result_showInmob {
    margin-top: -10px;
    cursor: pointer;
    border-top: 1px #e5e5e5 solid;
    padding-top: 15px;
  }
  .searchBannersec .bookingFomrBox {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 99999;
  }
  .searchBannersec {
    padding: 40px 0;
  }
  .searchBannersec h1{font-size: 33px !important;}
  .searchBannersec p{font-size: 20px !important;}
  .filterCloseBtn {
    display: block;
  }
  .SearchPageLeftFilterSection .accordion-item {
    display: none;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 99999;
    padding: 20px 0;
  }
  .SearchPageLeftFilterSection .accordion-button::after {
    display: none;
  }
  .search_result_showInmob {
    display: block;
  }
  .mobSortbySection {
    display: block;
  }
  .searchPageFlightSelectSection {
    padding-bottom: 0;
    padding-top: 90px;
  }
  .navbar-collapse {
    box-shadow: 0px 19px 20px #00000045;
    background: #fff;
  }
  .ProfileUserDtl .ProfileLftPrfBox .ProfileLftImageSec {
    margin-top: 80px;
  }
  .filightDetailsTop .FlightDetailsBox .FlightDetailsBoxRow {
    display: inline-block;
  }
  .filightDetailsTop
    .FlightDetailsBox
    .FlightDetailsBoxRow
    .FlightDetailsFlightdtl {
    width: 100%;
    margin-bottom: 10px;
  }
  .filightDetailsTop .FlightDetailsBox .FlightDetailsBoxSubHead {
    text-align: center;
    margin-top: 20px;
  }
  .SearcBookMultiCity .searchBookBoxBtn {
    margin-top: 30px;
    width: 100%;
  }
  .SearcBookMultiCity .searchBookDateBox {
    width: 180px;
    margin-right: 20px;
  }
  .bookingFomrBox .SearcBookMultiCity .SearcBookMultiCityRowBtn {
    display: inline-block;
  }
  #payemntFix {
    position: static !important;
    max-width: 100%;
  }
  #payemntFix.LeftFixScrl{
    position: static !important;
    max-width: 100%;
  }
  .mobSortbySection .filterBoxInMobile{   
     margin-top: -5px;
    margin-left: 4px;
    background-color: transparent;
    color: #242424;
  }
}
@media (max-width: 768px) {
  .homeFeatureSection{padding-top: 0;}
  .bookingFomrBox .SearcBookMultiCity .SearcBookMultiCityRow {
    display: inline-block;
  }
  .bookingFomrBox
    .SearcBookMultiCity
    .SearcBookMultiCityRow
    .SearcBookMultiCityBox:nth-child(2) {
    border: 0;
    border-top: 1px #ccc solid;
    border-bottom: 1px #ccc solid;
  }
  .bookingFomrBox .bookingFomrBoxHeadSc .bookingFomrBoxHeadtxt {
    position: static;
    margin-bottom: 5px;
  }
  .bookingFomrBox .bookingFomrBoxHeadSc {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .bookingFomrBoxHeabtns {
    width: 100%;
  }
  .topMainBanner .banner_top_cnt_bx h1 {
    font-size: 29px;
    line-height: 35px;
  }
  .topMainBanner .banner_top_cnt_bx h1 span {
    font-size: 25px;
  }
  .topMainBanner .banner_top_cnt_bx p {
    font-size: 14px;
  }
  .bookingFomrBox .searchBookBtmRow .searchBookBtmLocation .searchToggleBtnDv {
    display: none;
  }
  .bookingFomrBox .searchBookBtmRow .searchBookBtmLocation {
    border: 0;
    padding: 0;
    display: inline-block;
    margin-bottom: 0;
    height: auto;
  }
  .bookingFomrBox .searchBookBtmRow .searchBookBtmLocation .searchBookBtmBox {
    border: 0.5px solid #cccccc;
    border-radius: 10px;
    margin-bottom: 10px;
    padding-top: 5px;
  }
  .bookingFomrBox {
    margin-top: -26%;
  }
  .cancellationProtectionSec
    .cancellationProtectionbox
    .cancellationCntLft
    .cancellationCntLftPointsRow {
    display: inline-block;
  }
  .cancellationProtectionSec .cancellationProtectionbox .cancellationImgRight {
    margin-top: 30px;
  }
  .footerSection .footerCopyRight .footerCopyRightCnt {
    display: inline-block;
    text-align: center;
  }
  .footerSection .footerCopyRight .footerCopyRightCnt .DesgnDevelop {
    text-align: center;
  }
  .FlightSearchResultBoxSec
    .FlightSearchResultBox
    .FlightSearchResultBoxFirstDtl
    .FlightSearchResultBoxFirstDtlRow2 {
    display: inline-block;
  }

  .FlightSearchResultBoxSec
    .FlightSearchResultBox
    .FlightSearchResultBoxFirstDtl
    .FlightSearchResultBoxFirstDtlRow2
    .FlightSearchResultBoxFirstDtlRow2Lftdtl {
    width: 100%;
    display: inline-block;
  }
  .FlightSearchResultBoxSec
    .FlightSearchResultBox
    .FlightSearchResultBoxFirstDtl
    .FlightSearchResultBoxFirstDtlRow2
    .FlightSearchResultBoxFirstDtlRow2Lftdtl
    .FlightSearchRightFlight {
    align-items: center;
    margin-bottom: 10px;
  }
  .FlightSearchResultBoxSec
    .FlightSearchResultBox
    .FlightSearchResultBoxFirstDtl
    .FlightSearchResultBoxFirstDtlRow2
    .FlightSearchDtailBx
    span {
    text-align: center;
    margin-top: 20px;
  }
  .FlightSearchpageRightSec .FlightSearchpageRighttopSort {
    display: inline-block;
  }
  .FlightSearchpageRightSec
    .FlightSearchpageRighttopSort
    .FlightSearchpageRighttopSortBox {
    margin-bottom: 20px;
    border: 0;
  }
  .FlightSearchResultBoxSec
    .FlightSearchResultBox
    .FlightSearchResultBoxFirstDtl
    .FlightSearchResultBoxFirstDtlRow1
    .FlightSearchResultBoxFirstDtlRow1LCDprt
    .FlightResultDprtDtl {
    justify-content: center;
    font-size: 15px;
  }
  .FlightSearchResultBoxSec
    .FlightSearchResultBox
    .FlightSearchResultBoxFirstDtl
    .FlightSearchResultBoxFirstDtlRow2
    .FlightSearchResultBoxFirstDtlRow2Lftdtl
    .FlightSearchRightSelectInpt {
    position: absolute;
    top: 66px;
    height: 30px;
  }
  .FlightSearchResultBoxSec
    .FlightSearchResultBox
    .FlightSearchResultBoxFirstDtl
    .FlightSearchResultBoxFirstDtlRow2
    .FlightSearchResultBoxFirstDtlRow2Lftdtl
    .FlightSearchTtlHour {
    font-size: 11px;
  }
  .FlightSearchResultBoxSec .FlightSearchResultBox {
    padding: 0;
    padding-top: 80px;
  }
  .searchBannersec {
    padding: 40px 0;
  }
  .bookingFomrBox .bookingFomrBoxHeadSc .bookingFomrBoxHeadtxt {
    min-width: auto;
  }
  .FlightSearchpageRighttopSort {
    display: none !important;
  }
  .FlightSearchResultBoxFirstDtlRow2 {
    display: none !important;
  }
  .FlightSearchResultBoxFirstDtlRow2Mob {
    display: flex;
  }
  .FlightSearchResultBoxFirstDtlRow2MobBtn {
    display: grid;
  }
  .FlightSearchRightFlight .FlightSearchRightFlightImg {
    border-radius: 50%;
    padding: 5px;
    border: solid 1px #e5e5e5;
  }
  .FlightSearchResultBoxSec
    .FlightSearchResultBox
    .FlightSearchConfirmSection
    .FlightSearchConfirmSectionRow1
    .FlightSearchTtlAmount {
    position: absolute;
    top: 16px;
  }
  .mobHide {
    display: none;
  }
  .FlightSearchResultBoxFirstDtlRow2Mob
    .FlightSearchResultBoxFirstDtlRow2MobTxtDtl
    .FlightSearchResultBoxFirstDtlRow2MobTime {
    font-size: 15px;
  }
  .FilterMobApplyBtns {
    display: flex;
  }
  .ProfileUserDtl .BookingsCntRow .BookingsCntBox .BookingsCntBoxRow {
    display: inline-block;
  }
  .ProfileUserDtl
    .BookingsCntRow
    .BookingsCntBox
    .BookingsCntBoxRow
    .BookingsCntBoxFlightDateDtl {
    width: 100%;
  }
  .ProfileUserDtl
    .BookingsCntRow
    .BookingsCntBox
    .BookingsCntBoxRow
    .BookingsCntBoxFlightDtl {
    margin-bottom: 20px;
  }
  .ProfileUserDtl .BookingsCntRow .BookingsCntBox .flightBookedBtmDtl {
    display: inline-block;
  }
  .ProfileUserDtl
    .BookingsCntRow
    .BookingsCntBox
    .flightBookedBtmDtl
    .flightBookedBtmDtlPaidInfo {
    display: inline-block;
  }
  .ProfileUserDtl .ProfileLftPrfBox .ProfileLftImageSec {
    text-align: center;
  }
  .fliGhtWithoutAlertSec .fliGhtWithoutAlert {
    height: 130px;
    padding-top: 44px;
    width: 95%;
  }
  .FlightDetailsBookddtl:last-child .FlightDetailsBookddtlBx:last-child {
    text-align: left;
  }
  .filightDetailsTop
    .FlightDetailsBox
    .FlightDetailsBoxRow
    .FlightDetailsBookddtl {
    display: inline-block;
  }
  .filightDetailsTop
    .FlightDetailsBox
    .FlightDetailsBoxRow
    .FlightDetailsBookddtl
    div:nth-child(2) {
    width: 100%;
    display: inline-block;
    text-align: center;
    position: relative;
  }
  .filightDetailsTop
    .FlightDetailsBox
    .FlightDetailsBoxRow
    .FlightDetailsBookddtl
    div:nth-child(2)::before {
    border-bottom: 1px dashed #ccc;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    content: "";
    left: 0;
  }
  .HelpCenterFormBoxSec .HelpCenterFormBox p {
    margin-top: 10px;
  }
  .HelpCenterFormBoxSec .HelpCenterFormBox label {
    margin-bottom: 5px;
  }
  .paymentCardEnterBox .paymentCardEnterBoxHead .paymentUseSvdCardrow {
    display: inline-block;
  }
  .paymentCardEnterBox .paymentCardEnterBoxHead .paymentUseSvdCardrow label {
    margin-top: 15px;
  }
  .couponCodeSec {
    display: inline-block;
  }
  .couponCodeSec div:first-child{margin-bottom: 15px;}
  .couponCodeSec div img { height: 21px; margin-right: 5px; }
  .bookingpaymentReviewSec ol{padding-left: 10px;}
  .signinConfirmSection .signinConfirmBox .signInboxRight h2 {
    text-align: center;
  }
  .protectionSelctBoxCnt .row .col-md-6:first-child .cancelPrtctList {
    margin-bottom: 0;
  }
  .protectionSelctBoxCnt .row .col-md-6:last-child .cancelPrtctList {
    margin-top: 0;
  }
  .mobileHide {
    display: none;
  }
  .mobileShow {
    display: block !important;
  }
  .faqSection .faqSectionContant main .row #section-nav #nav-section {
    background-color: transparent !important;
  }
  .faqSection .faqSectionContant main .row #section-nav {
    background-color: transparent !important;
  }
  .faqSection .faqSectionContant main .navbar-collapse {
    display: block !important;
  }
  .faqSection .faqSectionContant main .navbar-toggler {
    display: none;
  }
  .earnpointTablFilterInput {
    min-width: 95%;
  }
  .ProfileUserDtl .accountLogedUserRightSec .accountLogedUserRightCnt {
    overflow: auto;
  }
  .mobSow {
    display: none !important;
  }
  .webShow {
    display: none !important;
  }
  .ProfileUserDtl .accountLogedUserRightSec .accountDtlHead2 {
    margin-top: 0;
  }
  .flightSarchinformationTabBtnSec .flightSarchinformationTabBtn {
    margin-bottom: 10px;
  }
  .flightSarchinformationTabBtnSec {
    display: inline-block;
  }
  .flightSearchDtlBtmSection .flightSearchDtlBtmImgSec{margin: auto;}
  .flightSearchDtlBtmSection{display: inline-block;}
  .flightSearchDtlBtmSection .flightSearchDtlBtmDprtArvl{display: inline-block;    width: 100%;}
  .flightSearchDtlBtmSection .flightSearchDtlBtmDprtArvl .FlightBtmDepartureBox{margin-top: 10px;text-align: center !important;}
  .FlightRightPanel{margin-top: 20px;}
  .passengerFlightDtl{padding-top: 0;}
  .filightDetailsTop{padding-top: 100px;}

}
@media (max-width: 500px) {
  .SearcBookMultiCity .searchBookDateBox {
    width: 100%;
    margin: 10px 0;
  }
}

.orderSuccessSection {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-top: 64px;
  padding: 40px 0;
}
.orderSuccessSection .orderSuccessMsgBox {
  width: 100%;
  text-align: center;
  display: inline-block;
  margin-bottom: 30px;
}
.orderSuccessSection .orderSuccessMsgBox .orderSuccessMsgBoxImage {
  width: 100%;
  height: auto;
  display: inline-block;
  text-align: center;
  margin-bottom: 20px;
}
.orderSuccessSection .orderSuccessMsgBox .orderSuccessMsgBoxImage img {
  max-width: 100%;
}
.orderSuccessSection .orderSuccessMsgBox .orderSuccessMsgHead {
  width: 100%;
  text-align: center;
  display: inline-block;
  font-size: 33px;
  color: #009362;
  margin-bottom: 3px;
  font-weight: 600;
}
.orderSuccessSection .orderSuccessMsgBox .orderSuccessMsgSubHead {
  width: 100%;
  text-align: center;
  display: inline-block;
  font-size: 18px;
  color: #666;
  margin-bottom: 5px;
} /*# sourceMappingURL=common.css.map */

.FLightSearchLoaderPopupSection {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.425);
  z-index: 999;
}
.FLightSearchLoaderPopup {
  width: 95%;
  height: 350px;
  max-width: 400px;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
  bottom: 0;
  right: 0;
  border-radius: 10px;
  padding: 20px;
}
.FLightSearchLoaderPopupHeaderImage {
  width: 100%;
  text-align: center;
  display: inline-block;
}

.FLightSearchLoaderPopupHeaderImageBox {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  margin: auto;
  border: solid 1px #e5e5e5;
}
.FLightSearchLoaderPopupHeaderImageBox img {
  width: 100%;
}
.FLightSearchLoaderPopupHeader {
  width: 100%;
  text-align: center;
  display: inline-block;
  margin-top: 20px;
}
.FLightSearchLoaderPopupHeader {
  width: 100%;
  text-align: center;
  display: inline-block;
  margin-top: 20px;
}
.FLightSearchLoaderPopupTextslide {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  position: relative;
  height: 20px;
}

@keyframes move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}
.FLightSearchLoaderPopupTextslide p {
  width: 100%;
  display: inline-block;
  color: #666;
  font-size: 14px;
  position: relative;
  white-space: nowrap;
  top: 0;
  left: 0;
  text-align: center;
  -webkit-animation: move 5s infinite;
  animation: move 5s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.modal-dialog {
  max-width: 700px;
  width: 96%;
}
.modal-dialog .passengerDtlBox {padding: 0;}
.modal-dialog .accountProfileviewSec{margin: 0;} 
.password_hint_Show {
  margin-top: 10px;
}
.disabled {
  cursor: not-allowed !important  ;
}
.PinterEvents {
  pointer-events: none;
}

.DateRangePickerInput_arrow {
  display: none !important;
}
.erroForm {
  opacity: 1;
  transform: none;
  display: block;
  justify-content: end;
  font-size: 11px;
  color: red !important;
  padding: -2px;
  margin: 2px;
  float: right;
  background: #ffe8e8;
  padding: 2px;
  width: fit-content;
}
.is-invalid {
  border: 1px solid red !important;
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  color: darkred;
}

.p-image {
  position: absolute;
  top: 167px;
  right: 30px;
  color: #666666;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.p-image:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.upload-button {
  position: absolute;
  top: 70px;
  left: 76px;
  /* color: red; */
  color: #666666;
}
.file-upload {
  display: none;
}

.upload-button:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: #999;
}
.ProfileLftImage label::after {
  content: "\f030";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
}
.ProfileLftImage img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
}

/* loaderdataa */

.loader {
  text-align: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  max-width: 35rem;
  height: 30rem;
  margin: 0 auto;
}

.wait {
  margin: 5rem 0;
}
.iata_code {
    font-size: 6rem;
    opacity:0.3;
  top: 52%;
  position: absolute;
  color: #0099cc;
  }
.departure_city {
  left: 0;
}

.arrival_city {
  right: 1.5rem;
}

.plane {
  position: absolute;
  margin: 0 auto;
  width: 100%;
}

.plane-img {
  -webkit-animation: spin 2.5s linear infinite;
  -moz-animation: spin 2.5s linear infinite;
  animation: spin 2.5s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.earth-wrapper {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  padding-top: 2.7rem;
}

.earth {
  width: 160px;
  height: 160px;
  background: url("assets/images/loader.gif");
  border-radius: 100%;
  background-size: 340px;
  animation: earthAnim 12s infinite linear;
  margin: 0 auto;
  border: 1px solid #CDD1D3;
}

@keyframes earthAnim {
  0% {background-position-x: 0;}
  100% {background-position-x: -340px;}
}

@media screen and (max-width: 420px) {
  .departure_city {
    left: 0;
    right: 0;
    top: 30%;
    position: absolute;
    margin: 0 auto;
  }
  
  .arrival_city {
    left: 0;
    right: 0;
    top: 93%;
    position: absolute;
    margin: 0 auto;
  }
  
  
}
#stickyDiv {
  background-color: #f1f1f1;
  padding: 10px;
  transition: all 0.3s ease;
}

/* CSS for the sticky behavior */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}
.lazy-load-image-loaded{
  width: 100%;
}
.green{
  color:#009362;
  text-shadow: black;
  font-size: 12px;
}
.Sessiontimeout {
  /* width: 100%;
  height: auto;
  display: inline-block;
  position: relative;
  padding: 40px;
  background-color: #1e1e1e; */
  /* background-image: url(./assets/images/search_banner.jpg); */
  /* background-repeat: no-repeat;
  margin-top: 74px;
  background-size: cover; */

  display: inline-block;
    height: 45px;
    background: #000;
    text-align: center;
    width: 100%;
    z-index: 999999;
    position: fixed;
    line-height: 45px;
    left: 0;
    bottom: 0;
    color: #fff;
    font-size: 1.143rem;
}
.Sessiontimeout h4{font-size: 17px;}
.modal-right .modal-dialog {
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
}
.payment_view_box{
  max-width: 530px;
  margin: auto;
  box-shadow: 0px 4px 34px rgb(0 0 0 / 16%) !important;
  border-radius: 20px !important;
  float: none !important;
}
.card-payment__container{
  width: 100% !important;
}
.p1--semibold{
  font-size: 20px !important;
    font-weight: 600 !important;
}
.main_wrapper_content{
   min-height: calc(100vh - 120px);
}


.activeDsiabled{
  pointer-events:none;
  
}
/**************Flight More detail***************/
.FlightSearchResultBoxSec .FlightSearchResultBox .FlightSearchResultBoxFirstDtl .flightSearchMoreDtl {
  background-color: #e6e6e6;
  padding: 10px;
  border-radius: 0px;
  border-bottom: 1px #e5e5e5 solid;
  position: relative;
}

.FlightSearchResultBoxSec .FlightSearchResultBox .FlightSearchResultBoxFirstDtl .flightSearchMoreDtl:before {
  content: "";
  position: absolute;
  right: 42px;
  top: -20px;
  border-bottom: 20px solid #e6e6e6;
  border-top: 0px solid transparent;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}

.flight_more_dtl_box_new {
  width: 100%;
  height: auto;
  display: inline-block;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  overflow: hidden;
}

.flight_more_dtl_box_cnt {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.flight_more_dtl_box_left_sec {
  width: 65%;
  height: 100%;
  background-color: #fff;
}

.flight_more_dtl_box_right_sec {
  width: 35%;
  min-height: 100%;
  background-color: #643f01;
}

.flight_more_dtl_box_left_head {
  width: 100%;
  height: auto;
  display: inline-block;
  background-color: #ffefd5;
  padding: 7px;
  color: #242424;
  font-size: 15px;
}

.flight_more_dtl_box_left_cnt {
  width: 100%;
  height: auto;
  display: inline-block;
  min-height: 100px;
  background-color: #fff;
}

.flight_more_dtl_box_lft_row {
  width: 100%;
  height: auto;
  display: inline-block;
  border-bottom: 3px #e5e5e5 solid;
  padding-bottom: 5px;
}

.flight_more_dtl_box_lft_row_head {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px #e5e5e5 solid;
  margin-bottom: 10px;
}

.flight_more_dtl_box_lft_row_cnt1 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.flight_mr_dtl_head {
  font-size: 15px;
  color: #242424;
  line-height: 14px;
}

.flight_mr_dtl_img {
  max-width: 50px;
}

.flight_mr_dtl_img img {
  max-width: 100%;
}

.flight_more_dtl_box_lft_row_cnt2 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: end;
}

.flight_more_dtl_box_lft_row_cnt2_ico {
  max-width: 30px;
  width: 100%;
  font-size: 17px;
  color: #767676;
}
.round-button {
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  height:50px;
  border: none;
  border-radius: 50%;
  background-color: #fff; /* Choose your desired background color */
  color: #000000; /* Choose your desired text color */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a box shadow for a raised effect */
}
.contact_head{
  font-weight: bold;
  margin-bottom: 40px;
  margin-top: 40px;
}
.contactus_box{
  width: 100%;
  display: inline-block;
  margin-bottom: 30px;
}
.contactus_box a{
  width: 100%;
  display: inline-block;
  font-size: 20px;
  color: #242424;
  text-decoration: dashed;
  margin-top: 10px;
}



.flight_more_dtl_box_left_cnt .flightSearchDtlBtmDprtArvl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 3 1;
  max-width: 500px;
  margin: auto;
}
.flight_more_dtl_box_left_cnt .flightSearchDtlBtmDprtArvl .FlightBtmDepartureBox {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  flex: 0.75 1;
}
.flight_more_dtl_box_left_cnt .flightSearchDtlBtmDprtArvl .FlightBtmDepartureBox span {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  color: #868686;
  display: inline-block;
}
.flight_more_dtl_box_left_cnt .flightSearchDtlBtmDprtArvl .FlightDtlTtlDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1.5 1;
  text-align: center;
  padding: 0 10px;
}
.flight_more_dtl_box_left_cnt .flightSearchDtlBtmDprtArvl .FlightDtlTtlDetail .FlightDtlTtlHour {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: 13px;
  color: #000;
  position: relative;
}
.flight_more_dtl_box_left_cnt .flightSearchDtlBtmDprtArvl .FlightDtlTtlDetail .FlightDtlTtlHour strong {
  background-color: #fff;
  position: relative;
  padding: 0 5px;
  padding-top: 2px;
  font-weight: normal;
}
.flight_more_dtl_box_left_cnt .flightSearchDtlBtmDprtArvl .FlightDtlTtlDetail .FlightDtlTtlHour::before {
  width: 100%;
  height: 1px;
  border-bottom: 1px #ccc dashed;
  position: absolute;
  top: 50%;
  left: 0;
  content: "";
}
.flight_more_dtl_box_left_cnt .flightSearchDtlBtmDprtArvl .FlightDtlTtlDetail .FlightDtlTtlflightDrtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  color: #000;
}

.flight_more_dtl_box_right_tab_sec {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding: 10px 5px;
  border-bottom: 1px #362200 solid;
  gap: 7px ;
}

.flight_more_dtl_box_right_tab_sec a {
  color: #D9D9D9;
  text-decoration: none;
  font-size: 13px;
}

.flight_more_dtl_box_right_tab_sec a.active {
  color: #fff;
  position: relative;
  height: 100%;
}

.flight_more_dtl_box_right_tab_sec a.active::before {
  width: 100%;
  height: 2px;
  background-color: #fff;
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
}

.flight_more_dtl_box_right_tab_cnt1 {
  width: 100%;
  display: inline-block;
  padding: 10px;
}

.flight_more_dtl_box_right_tab_cnt1_lst {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  color: #fff;
  margin-bottom: 10px;
  justify-content: space-between;
}

.flight_more_dtl_box_right_tab_cnt1_2nd {
  width: 100%;
  /* display: flex; */
  align-items: center;
  gap: 10px;
  font-size: 13px;
  color: #fff;
  margin-bottom: 10px;
}
.flight_more_dtl_box_right_tab_cnt1_2nd p{margin-bottom: 0;}
.flight_more_dtl_box_right_tab_cnt1_2nd i{margin-right: 4px;}
.button--48{margin-bottom: 40px;}
.change_plane_box_sec{
  width: 100%;
  display: inline-block;
  text-align: center;
  position: relative;
}

.change_plane_box{
  width: auto;padding: 5px 20px;
  background-color: #ddf8ff;
    border-radius: 30px;
    display: inline-block;
    position: relative;
    font-size: 13px;
    padding-left: 30px ;
    padding-right: 30px ;
  }
  .change_plane_box::before{
    width: 20px;
    height: 7px;
    position: absolute;
    left: -20px;
    top: 10px;
    border-bottom: 1px #ccc solid;
    content: '';
  }
  .change_plane_box::after{
    width: 20px;
    height: 7px;
    position: absolute;
    right: -20px;
    top: 10px;
    border-bottom: 1px #ccc solid;
    content: '';
  }

  .BookingsCntBoxFlightDtl .change_plane_box_sec{text-align: left;}
  .BookingsCntBoxFlightDtl .change_plane_box{margin-left: 30px;}

  .stickyLogo{display: none;}
  .fixed-header{
      background-color: #fff;
      padding: 10px 0;
      box-shadow: 0px 0px 20px #00000026;
  }
  .fixed-header .logoTopSection img{display: none;}
  .fixed-header .logoTopSection img.stickyLogo{display: block;}
  .fixed-header .navbar-expand-lg .navbar-nav .nav-link{color: #242424;}


@media (max-width: 768px) {
.DayPicker_transitionContainer{width: 100% !important;height: auto !important;}
.CalendarMonthGrid__horizontal{ width: 100% !important; display: flex; flex-wrap: wrap; position: relative !important;}
.CalendarMonthGrid_month__horizontal{width: 100% !important;}
.CalendarMonth{width: 100% !important;display: inline-block;}
.DayPicker__withBorder{width: 100% !important;display: inline-block;}
.DayPicker__withBorder div{width: 100% !important;}
.DayPicker__withBorder .DayPickerNavigation_button__horizontalDefault{width: 37px !important;}
.DayPicker_weekHeader{left: 0 !important;}
.DayPicker_weekHeader_ul{display: flex;}
.CalendarMonth{padding-left: 0 !important;}

/* .CalendarMonthGrid_month__hidden{display: none;} */
.passengerFlightDtl .passengerDtlBox .passengerDtlFrom{ flex-wrap: wrap;gap: 14px;}
.passengerFlightDtl .passengerDtlBox .passengerDtlFrom .col-md-10{width: 100%;}
.searchBannersec .bookingFomrBox { overflow: auto;}
.bookingFomrBox .DateInput__small {width: 110px !important;}
.FlightDetailsBookddtlBx{width: 100%;}
}

@media (max-width: 500px) {
.flight_more_dtl_box_left_cnt .flightSearchDtlBtmDprtArvl{flex-wrap: wrap;}
.flight_more_dtl_box_left_cnt .flightSearchDtlBtmDprtArvl .FlightBtmDepartureBox{flex: none;width: 100%;text-align: center !important;}
.flight_more_dtl_box_left_cnt .flightSearchDtlBtmDprtArvl .FlightDtlTtlDetail{margin: 15px 0;}
.searchBookDateBox .DateRangePicker{width: 95%;}
}



/*# sourceMappingURL=common.css.map */